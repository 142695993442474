import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'; // Importer useNavigate for omdirigering

const VerifyEmail = () => {
  const [status, setStatus] = useState('loading'); // loading, success, error
  const location = useLocation(); // Bruk useLocation for å hente location-objektet
  const navigate = useNavigate(); // Bruk useNavigate for å omdirigere brukeren

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search); // location.search vil nå fungere
      const token = params.get('token');

      try {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/users/verify-email?token=${token}`);
        setStatus('success');
        // Omdiriger til login etter 3 sekunder
        setTimeout(() => navigate('/login'), 5000);
      } catch (error) {
        setStatus('error');
      }
    };

    verifyEmail();
  }, [location.search, navigate]); // Legg til navigate som avhengighet

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5, textAlign: 'center' }}>
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && (
        <>
          <Alert severity="success">Email verified successfully!</Alert>
          <Typography variant="body2" sx={{ mt: 2 }}>
            You will be redirected to the login page shortly.
          </Typography>
        </>
      )}
      {status === 'error' && <Alert severity="error">Invalid or expired token</Alert>}
    </Box>
  );
};

export default VerifyEmail;