import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, Grid, useMediaQuery } from '@mui/material';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
    const [memberCount, setMemberCount] = useState(null);
    const { t } = useTranslation(); // Henter oversettelsesfunksjonen
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:900px)'); // Detects medium-sized screens
    const roundedMemberCount = Math.round(memberCount / 100) * 100;
    const trackClick = async (link) => {
        try {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stats/track-click`, {
            link,
          });
        } catch (error) {
          console.error('Error tracking click:', error.message);
        }
      };
    useEffect(() => {
        const fetchMemberCount = async () => {
          try {
            const response = await axios.get(`${BACKEND_URL}/api/stats/discord/memberCount`);
            setMemberCount(response.data.memberCount);
          } catch (error) {
            console.error('Error fetching Discord member count:', error);
          }
        };
    
        fetchMemberCount();
      }, [BACKEND_URL]);

    return (
        <Box
            sx={{
                height: isMobile ? '80vh' : isTablet ? '65vh' : '60vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url('FlockTradeHeroSection.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                textAlign: 'center',
                paddingTop: isMobile || isTablet ? '56px' : '64px',
                paddingX: 2,
                paddingBottom: 4,
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    backdropFilter: 'blur(5px)',
                    p: 3,
                    borderRadius: 2,
                    maxWidth: isTablet ? '80vw' : '90vw',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark overlay for readability
                    mb: 2,
                }}
            >
                <Typography
                    variant={isMobile ? 'h4' : isTablet ? 'h3' : 'h2'}
                    component="h1"
                    sx={{ fontWeight: 'bold' }}
                >
                {t('hero.welcome')}
                </Typography>
                <Typography variant={isMobile ? 'body1' : 'h5'} component="p" sx={{ mt: 1 }}>
                {t('hero.gateway')}
                </Typography>
                <Button 
                variant="contained" 
                color="secondary" 
                size={isMobile || isTablet ? 'medium' : 'large'} 
                onClick={() => {trackClick('CTA-Hero: Join Discord');window.open('https://discord.com/invite/sQjPufbufK', '_blank')}}
                sx={{ mt: 2, ml: 2 }}
            >
            {t('hero.joinDiscord')}
            </Button>
            </Box>
            

            {/* Statistics Section */}
            <Grid container spacing={2} justifyContent="center" sx={{ width: '100%', maxWidth: isTablet ? '700px' : '600px', mt: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        p: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        boxShadow: 3,
                    }}>
                        <Typography variant="h5" fontWeight="bold">
                            <CountUp end={17} duration={2} /> {t('hero.years')}
                        </Typography>
                        <Typography variant="body2">{t('hero.experience')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        p: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        boxShadow: 3,
                    }}>
                        <Typography variant="h5" fontWeight="bold">
                            <CountUp end={24} duration={2} />/7
                        </Typography>
                        <Typography variant="body2">{t('hero.onlineSupport')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        p: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        boxShadow: 3,
                    }}>
                        <Typography variant="h5" fontWeight="bold">
                            <CountUp end={roundedMemberCount} duration={2} />+
                        </Typography>
                        <Typography variant="body2">{t('hero.satisfiedMembers')}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroSection;
