import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Divider,
} from '@mui/material';
import axios from 'axios';

const AcademyHome = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/academy/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Box p={4}>
      {/* Hero Section */}
      <Box
        sx={{
          background: 'linear-gradient(to right, #6a11cb, #2575fc)',
          color: '#fff',
          textAlign: 'center',
          py: 8,
          borderRadius: '8px',
        }}
      >
        <Typography variant="h3" fontWeight="bold">
          Velkommen til FlockTrade Academy
        </Typography>
        <Typography variant="h6" mt={2}>
          Ta første skritt mot å mestre trading med våre profesjonelle kurs!
        </Typography>
      </Box>

      <Divider sx={{ my: 4 }} />

      {/* Categories */}
      <Typography variant="h4" mb={4}>
        Velg din kategori
      </Typography>
      <Grid container spacing={4}>
        {categories.map((category) => (
          <Grid item xs={12} md={4} key={category.id}>
            <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="h5" fontWeight="bold">
                {category.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" mt={1} mb={2}>
                {category.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = `/academy/category/${category.id}`)}
              >
                Utforsk kurs
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AcademyHome;
