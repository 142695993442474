import React from 'react';
import { Box, Typography, Tabs, Tab, Paper } from '@mui/material';
import AdminCategories from './AdminCategories';
import AdminCourses from './AdminCourses';
import AdminSections from './AdminSections';
import AdminQuizzes from './AdminQuizzes';
import AdminQuestions from './AdminQuestions';

const AdminAcademy = () => {
    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                Admin Academy
            </Typography>
            <Paper elevation={3} sx={{ mb: 4 }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Categories" />
                    <Tab label="Courses" />
                    <Tab label="Sections" />
                    <Tab label="Quizzes" />
                    <Tab label="Questions" />
                </Tabs>
            </Paper>
            {activeTab === 0 && <AdminCategories />}
            {activeTab === 1 && <AdminCourses />}
            {activeTab === 2 && <AdminSections />}
            {activeTab === 3 && <AdminQuizzes />}
            {activeTab === 4 && <AdminQuestions />}
        </Box>
    );
};

export default AdminAcademy;
