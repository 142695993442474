import React from 'react';
import { Box, Typography, Button, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation(); // Henter oversettelser

  const handleDiscordRegistration = () => {
    // Send brukeren til backend-ruten for Discord OAuth registrering
    const registrationWindow = window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/discord/register`,
      'Discord Registration',
      'width=500,height=600'
    );

    // Sjekk om popup-vinduet er lukket
    const checkPopupClosed = setInterval(() => {
      if (registrationWindow.closed) {
        clearInterval(checkPopupClosed);
        // Eventuelt refresh for å oppdatere status
        window.location.reload();
      }
    }, 1000);
  };

  return (
    <Box textAlign="center" sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t('signup.title')}
      </Typography>

      <Alert
      severity="info"
      sx={{
        mb: 3,
        mx: 'auto', // Sentrerer horisontalt
        maxWidth: 700, // Gjør boksen smalere
        textAlign: 'center', // Sentrer tekst
      }}
    >
    {t('signup.info')}
    <Typography
      component="span"
      sx={{
        color: 'green', // Grønn farge for Discord-stil
        fontWeight: 'bold',
      }}
    >
      {' @FlockTrader '}
    </Typography>
    {t('signup.info2')}
  </Alert>

      <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
        {t('signup.instructions')}
      </Typography>

      <Button variant="contained" color="primary" onClick={handleDiscordRegistration}>
        {t('signup.discordButton')}
      </Button>
    </Box>
  );
};

export default SignUp;
