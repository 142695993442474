import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert, Paper } from '@mui/material';
import axios from 'axios';

const Unsubscribe = () => {
    const [step, setStep] = useState(1); // 1: Request Code, 2: Verify Code
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const requestCode = async () => {
        try {
            setError('');
            setMessage('');
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter/unsubscribe/request-code`, { email });
            setMessage('Verification code sent to your email.');
            setStep(2); // Gå til neste steg
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to request verification code.');
        }
    };

    const verifyCodeAndUnsubscribe = async () => {
        try {
            setError('');
            setMessage('');
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter/unsubscribe`, { email, code });
            setMessage('You have successfully unsubscribed.');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to unsubscribe.');
        }
    };

    return (
        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={2}
    >
        <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
            <Typography variant="h5" gutterBottom>
                Unsubscribe from Newsletter
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Enter your email below to unsubscribe from our newsletter.
            </Typography>
            {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            {step === 1 && (
                <>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={requestCode}>
                        Request Verification Code
                    </Button>
                </>
            )}

            {step === 2 && (
                <>
                    <Typography gutterBottom>
                        Enter the verification code sent to {email}.
                    </Typography>
                    <TextField
                        label="Verification Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={verifyCodeAndUnsubscribe}>
                        Confirm Unsubscribe
                    </Button>
                </>
            )}
            </Paper>
        </Box>
    );
};

export default Unsubscribe;
