import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Divider  } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faDiscord, faFacebook, faTiktok, faSnapchat } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../context/ThemeContext';
import { useUserData } from '../auth/UserData'; // Importer autentiseringskontekst
import axios from 'axios';

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme(); // Hent aktivt tema
  const { darkMode } = useTheme(); // Henter darkMode-verdien fra din kontekst
  const { user } = useUserData(); // Hent `user` fra konteksten

  const trackClick = async (link) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stats/track-click`, {
        link,
      });
    } catch (error) {
      console.error('Error tracking click:', error.message);
    }
  };

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{
        marginTop: 4,
        top: 'auto',
        bottom: 0,
        backgroundColor: darkMode ? '#0A1929' : '#007FFF', // Dynamisk bakgrunnsfarge
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 2,
        }}
      >
        {/* Footer Text */}
        <Typography variant="body2" align="center" sx={{ mb: { xs: 1, md: 0 } }}>
          {t('footer.copyright', { year: new Date().getFullYear() })}
        </Typography>

        {/* Social Media Links */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <IconButton
            aria-label="Instagram"
            color="inherit"
            onClick={() => { trackClick('Sosial: Instagram'); window.open('https://www.instagram.com/flocktrade/', '_blank')}}
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Discord"
            color="inherit"
            onClick={() => { trackClick('Sosial: Discord'); window.open('https://discord.com/invite/sQjPufbufK', '_blank')}}
          >
            <FontAwesomeIcon icon={faDiscord} size="lg" />
          </IconButton>

          <IconButton
            aria-label="TikTok"
            color="inherit"
            onClick={() => {trackClick('Sosial: TikTok'); window.open('https://tiktok.com/@flocktrade', '_blank')}}
          >
            <FontAwesomeIcon icon={faTiktok} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Facebook"
            color="inherit"
            onClick={() => { trackClick('Sosial: Facebook'); window.open('https://facebook.com/flocktradecom', '_blank')}}
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Snapchat"
            color="inherit"
            onClick={() => {trackClick('Sosial: Snapchat');window.open('https://www.snapchat.com/add/FlockTrade.com', '_blank')}}
          >
            <FontAwesomeIcon icon={faSnapchat} size="lg" />
          </IconButton>
        </Box>
      </Toolbar>

            {/* Divider */}
            <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />

            {/* Disclaimer Section */}
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography 
                variant="caption" 
                color={darkMode ? 'text.secondary' : '#fffff'} // Dynamisk fargevalg
                >
                {t('footer.disclamer')}
              </Typography>
            </Box>

    </AppBar>
  );
};

export default Footer;
