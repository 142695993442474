import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const AnalyticsDashboard = () => {
  const [pageViews, setPageViews] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [loading, setLoading] = useState(true);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      setLoading(true);
      try {
        const pageViewResponse = await axios.get(`${BACKEND_URL}/api/stats/visit-page-views`);
        const clickResponse = await axios.get(`${BACKEND_URL}/api/stats/visit-clicks`);

        setPageViews(Array.isArray(pageViewResponse.data) ? pageViewResponse.data : []);
        setClicks(Array.isArray(clickResponse.data) ? clickResponse.data : []);
      } catch (error) {
        console.error('Error fetching analytics data:', error.message);
        setPageViews([]);
        setClicks([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, [BACKEND_URL]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
        <CircularProgress />
      </Box>
    );
  }

  // Prepare data for charts
  const pageViewLabels = pageViews.map((view) => `${view.page || 'Unknown Page'}`);
  const totalPageViews = pageViews.map((view) => parseInt(view.totalViews, 10));
  const uniquePageViews = pageViews.map((view) => parseInt(view.uniqueViews, 10));

  const pageViewData = {
    labels: pageViewLabels,
    datasets: [
      {
        label: 'Total Views',
        data: totalPageViews,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Unique Views',
        data: uniquePageViews,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  const clickLabels = clicks.map((click) => `${click.tag || 'Unknown'}: ${click.text || 'N/A'}`);
  const totalClicks = clicks.map((click) => parseInt(click.totalClicks, 10));
  const uniqueClicks = clicks.map((click) => parseInt(click.uniqueClicks, 10));

  const clickData = {
    labels: clickLabels,
    datasets: [
      {
        label: 'Total Clicks',
        data: totalClicks,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Unique Clicks',
        data: uniqueClicks,
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  return (
    <Box my={4} px={{ xs: 2, sm: 4 }}>
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
        Website Analytics Dashboard
      </Typography>

      <Grid container spacing={4}>
        {/* Page Views Section */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom textAlign="center">
                Page Views
              </Typography>
              <Typography variant="body2" textAlign="center" sx={{ mb: 2 }}>
                Total Pages Viewed: {totalPageViews.reduce((a, b) => a + b, 0)} | Unique Views: {uniquePageViews.reduce((a, b) => a + b, 0)}
              </Typography>
              <Box>
                <Bar data={pageViewData} options={{ responsive: true, maintainAspectRatio: false }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Clicks Section */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom textAlign="center">
                Click Analytics
              </Typography>
              <Typography variant="body2" textAlign="center" sx={{ mb: 2 }}>
                Total Clicks: {totalClicks.reduce((a, b) => a + b, 0)} | Unique Clicks: {uniqueClicks.reduce((a, b) => a + b, 0)}
              </Typography>
              <Box>
                <Bar data={clickData} options={{ responsive: true, maintainAspectRatio: false }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Detailed Table Section */}
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom textAlign="center">
                Detailed View
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Page</TableCell>
                    <TableCell>Total Views</TableCell>
                    <TableCell>Unique Views</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageViews.map((view, index) => (
                    <TableRow key={index}>
                      <TableCell>{view.page}</TableCell>
                      <TableCell>{view.totalViews}</TableCell>
                      <TableCell>{view.uniqueViews}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsDashboard;
