import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';

const AdminSections = () => {
  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const [newSection, setNewSection] = useState({
    name: '',
    description: '',
    content: '',
    courseId: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionResponse = await axios.get('/api/academy/admin/sections');
        const courseResponse = await axios.get('/api/academy/admin/courses');
        setSections(sectionResponse.data);
        setCourses(courseResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenDialog = (section) => {
    setCurrentSection(section || null);
    setNewSection(section || { name: '', description: '', content: '', courseId: '' });
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      if (currentSection) {
        await axios.put(`/api/academy/admin/sections/${currentSection.id}`, newSection);
      } else {
        await axios.post('/api/academy/admin/sections', newSection);
      }
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Error saving section:', error.message);
    }
  };

  const handleOpenDeleteDialog = (section) => {
    setSectionToDelete(section);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSectionToDelete(null);
  };

  const confirmDelete = async () => {
    if (sectionToDelete) {
      try {
        await axios.delete(`/api/academy/admin/sections/${sectionToDelete.id}`);
        setDeleteDialogOpen(false);
        window.location.reload();
      } catch (error) {
        console.error('Error deleting section:', error.message);
      }
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Box p={4}>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Administrer Seksjoner
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Legg til seksjon
      </Button>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Beskrivelse</TableCell>
              <TableCell>Kurs</TableCell>
              <TableCell>Handlinger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sections.map((section) => (
              <TableRow key={section.id}>
                <TableCell>{section.name}</TableCell>
                <TableCell>{section.description}</TableCell>
                <TableCell>
                  {courses.find((course) => course.id === section.courseId)?.name || 'Ukjent'}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleOpenDialog(section)} variant="outlined" sx={{ mr: 1 }}>
                    Rediger
                  </Button>
                  <Button onClick={() => handleOpenDeleteDialog(section)} variant="outlined" color="error">
                    Slett
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{currentSection ? 'Rediger Seksjon' : 'Legg til Seksjon'}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="subtitle1">Kurs</Typography>
            <Select
              fullWidth
              value={newSection.courseId}
              onChange={(e) => setNewSection({ ...newSection, courseId: e.target.value })}
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1">Navn</Typography>
            <input
              type="text"
              style={{ width: '100%', padding: '8px' }}
              value={newSection.name}
              onChange={(e) => setNewSection({ ...newSection, name: e.target.value })}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1">Beskrivelse</Typography>
            <input
              type="text"
              style={{ width: '100%', padding: '8px' }}
              value={newSection.description}
              onChange={(e) => setNewSection({ ...newSection, description: e.target.value })}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1">Innhold</Typography>
            <Editor
            apiKey="ccol78ntid2nnnsti0in1cj0nok8rble3zqu4trm3u2co9nj"
            value={newSection.content}
            init={{
              height: 500,
              menubar: true,
              plugins: 'link image code lists table code',
              valid_elements: '*[*]', // Tillat alle elementer og attributter
              extended_valid_elements: 'html,body',
              toolbar:
                'undo redo | bold italic underline | image insertCarouselButton | alignleft aligncenter alignright alignjustify | bullist numlist | table | link image | code',
              relative_urls: false, // Bruk absolutt URL
              remove_script_host: false, // Behold domenet i URL-en
              document_base_url: 'https://flocktrade.com/', // Basen for relative URL-er
          
              setup: (editor) => {
                // Legg til en knapp for bildekarusell
                editor.ui.registry.addButton('insertCarouselButton', {
                  text: 'Insert Carousel',
                  icon: 'gallery',
                  onAction: () => {
                    editor.windowManager.open({
                      title: 'Insert Image Carousel',
                      body: {
                        type: 'panel',
                        items: [
                          {
                            type: 'textarea',
                            name: 'images',
                            label: 'Image URLs (comma-separated)',
                            placeholder: 'https://example.com/image1.jpg, https://example.com/image2.jpg',
                          },
                          {
                            type: 'input',
                            name: 'height',
                            label: 'Carousel Height (px)',
                            placeholder: '300',
                          },
                          {
                            type: 'input',
                            name: 'width',
                            label: 'Carousel Width (px)',
                            placeholder: '600',
                          },
                        ],
                      },
                      buttons: [
                        {
                          type: 'cancel',
                          text: 'Cancel',
                        },
                        {
                          type: 'submit',
                          text: 'Insert',
                        },
                      ],
                      onSubmit: (api) => {
                        const data = api.getData();
                        const imageUrls = data.images.split(',').map((url) => url.trim());
                        const height = data.height || '300';
                        const width = data.width || '600';
          
                        // Generer HTML for karusellen
                        const carouselHtml = `
                          <div class="image-carousel" style="position: relative; width: ${width}px; height: ${height}px; overflow: hidden;">
                            <div class="carousel-images" style="display: flex; transition: transform 0.5s ease;">
                              ${imageUrls
                                .map(
                                  (url) =>
                                    `<img src="${url}" style="width: 100%; flex-shrink: 0; object-fit: cover;" />`
                                )
                                .join('')}
                            </div>
                            <button class="carousel-prev" style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%); background-color: rgba(0,0,0,0.5); color: white; border: none; cursor: pointer;">&#10094;</button>
                            <button class="carousel-next" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); background-color: rgba(0,0,0,0.5); color: white; border: none; cursor: pointer;">&#10095;</button>
                          </div>
                          <script>
                            (function() {
                              const carousel = document.querySelector('.image-carousel');
                              const images = carousel.querySelector('.carousel-images');
                              const prevButton = carousel.querySelector('.carousel-prev');
                              const nextButton = carousel.querySelector('.carousel-next');
                              let index = 0;
          
                              prevButton.addEventListener('click', () => {
                                index = Math.max(0, index - 1);
                                images.style.transform = \`translateX(-\${index * 100}%)\`;
                              });
          
                              nextButton.addEventListener('click', () => {
                                index = Math.min(images.children.length - 1, index + 1);
                                images.style.transform = \`translateX(-\${index * 100}%)\`;
                              });
                            })();
                          </script>
                        `;
          
                        editor.insertContent(carouselHtml);
                        api.close();
                      },
                    });
                  },
                });
              },
          
              // Konfigurer bildeopplasting og medievelger
              file_picker_callback: async (callback, value, meta) => {
                if (meta.filetype === 'image') {
                  // Hent eksisterende filer fra backend
                  try {
                    const response = await axios.get(`/api/academy/admin/uploads`);
                    const files = response.data;
          
                    // Opprett modal for bildevalg
                    const overlay = document.createElement('div');
                    overlay.style.position = 'fixed';
                    overlay.style.top = '0';
                    overlay.style.left = '0';
                    overlay.style.width = '100%';
                    overlay.style.height = '100%';
                    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    overlay.style.zIndex = '10000';
          
                    const modal = document.createElement('div');
                    modal.style.position = 'fixed';
                    modal.style.top = '50%';
                    modal.style.left = '50%';
                    modal.style.transform = 'translate(-50%, -50%)';
                    modal.style.backgroundColor = '#fff';
                    modal.style.padding = '20px';
                    modal.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                    modal.style.borderRadius = '8px';
                    modal.style.zIndex = '10001';
          
                    const fileList = document.createElement('div');
                    fileList.style.maxHeight = '300px';
                    fileList.style.overflowY = 'auto';
                    fileList.style.display = 'grid';
                    fileList.style.gridTemplateColumns = 'repeat(auto-fit, minmax(100px, 1fr))';
                    fileList.style.gap = '10px';
          
                    // Legg til filer i modal
                    files.forEach((file) => {
                      const fileItem = document.createElement('div');
                      fileItem.style.cursor = 'pointer';
                      fileItem.style.textAlign = 'center';
          
                      const thumbnail = document.createElement('img');
                      thumbnail.src = file.url;
                      thumbnail.style.maxWidth = '80px';
                      thumbnail.style.maxHeight = '80px';
                      thumbnail.style.objectFit = 'cover';
                      thumbnail.style.borderRadius = '4px';
                      thumbnail.style.marginBottom = '5px';
          
                      fileItem.appendChild(thumbnail);
                      fileItem.onclick = () => {
                        callback(file.url, { alt: file.name });
                        document.body.removeChild(modal);
                        document.body.removeChild(overlay);
                      };
          
                      fileList.appendChild(fileItem);
                    });
          
                    modal.appendChild(fileList);
          
                    // Lukk-knapp
                    const closeButton = document.createElement('button');
                    closeButton.textContent = 'Close';
                    closeButton.style.marginTop = '10px';
                    closeButton.onclick = () => {
                      document.body.removeChild(modal);
                      document.body.removeChild(overlay);
                    };
          
                    modal.appendChild(closeButton);
          
                    // Legg modal og overlay til i dokumentet
                    document.body.appendChild(overlay);
                    document.body.appendChild(modal);
                  } catch (error) {
                    console.error('Failed to fetch media files:', error);
                    alert('Unable to load media files. Please try again.');
                  }
                }
              },
          
              images_upload_handler: async (blobInfo, success, failure) => {
                try {
                  const formData = new FormData();
                  formData.append('file', blobInfo.blob());
          
                  // Bytt til din backend-endepunkt for bildeopplasting
                  const response = await axios.post(`/api/academy/admin/upload`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
          
                  if (response.data?.url) {
                    success(response.data.url); // Returner den fullstendige URL-en
                  } else {
                    failure('Failed to upload image');
                  }
                } catch (error) {
                  console.error('Image upload failed:', error);
                  failure('Image upload failed');
                }
              },
            }}
            onEditorChange={(content) => setNewSection({ ...newSection, content })}
          />
          
          
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Avbryt</Button>
          <Button onClick={handleSave} variant="contained">
            Lagre
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Bekreft sletting</DialogTitle>
        <DialogContent>
          <Typography>
            Er du sikker på at du vil slette seksjonen "{sectionToDelete?.name}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Avbryt</Button>
          <Button onClick={confirmDelete} variant="contained" color="error">
            Slett
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminSections;
