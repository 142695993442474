import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';

// Registrer nødvendige komponenter for Doughnut Chart
ChartJS.register(ArcElement, Tooltip, Legend);

const WeeklyStats = () => {
  const { t } = useTranslation(); // Oversettelser
  const [weeklyData, setWeeklyData] = useState(null);
  const [timeframe, setTimeframe] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());

  // Hent data basert på valgt tidsperiode
  useEffect(() => {
    const formattedDate = currentDate.toISOString().split('T')[0];
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/signals/stats/weekly?timeframe=${timeframe}&date=${formattedDate}`
    )
      .then((res) => res.json())
      .then(setWeeklyData)
      .catch((err) => console.error('Error fetching weekly stats:', err));
  }, [timeframe, currentDate]);

  // Navigasjon for dato
  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    if (timeframe === 'week') {
      newDate.setDate(newDate.getDate() - 7);
    } else if (timeframe === 'month') {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (timeframe === 'week') {
      newDate.setDate(newDate.getDate() + 7);
    } else if (timeframe === 'month') {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  if (!weeklyData) return <Typography>{t('loading')}</Typography>;

  const { summary, providers } = weeklyData;

  // Beregn samlet oversikt for alle providers
  const overallSummary = providers.reduce(
    (acc, provider) => {
      acc.TP1 += provider.TP1 || 0;
      acc.TP2 += provider.TP2 || 0;
      acc.TP3 += provider.TP3 || 0;
      acc.TP4 += provider.TP4 || 0;
      acc.TP5 += provider.TP5 || 0;
      acc.stopLoss += provider.stopLoss || 0;
      acc.totalSignals += provider.totalSignals || 0;
      return acc;
    },
    { TP1: 0, TP2: 0, TP3: 0, TP4: 0, TP5: 0, stopLoss: 0, totalSignals: 0 }
  );

  const getChartData = (data) => ({
    labels: [t('signals.stats.tp1'), t('signals.stats.tp2'), t('signals.stats.tp3'), t('signals.stats.tp4'), t('signals.stats.tp5'), t('signals.stats.sl')],
    datasets: [
      {
        data: [data.TP1, data.TP2, data.TP3, data.TP4, data.TP5, data.stopLoss],
        backgroundColor: ['#4CAF50', '#8BC34A', '#CDDC39', '#FFC107', '#FF5722', '#F44336'],
      },
    ],
  });

  const getDoughnutOptions = (winRate) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      centerText: { winRate: winRate && typeof winRate === 'number' ? winRate : '' },
    },
  });

  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw(chart) {
      const { width, height, ctx } = chart;
      const winRate = chart.options.plugins.centerText?.winRate;
  
      if (!winRate) return; // Ikke tegn noe hvis winRate er tom
  
      // Beregn fontstørrelse basert på diagrammets bredde
      const fontSize = Math.min(width, height) / 8; // Juster divisor for å endre skala
  
      ctx.save();
      ctx.font = `bold ${fontSize}px Arial`; // Responsiv fontstørrelse
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#fff';
      ctx.fillText(`${winRate}%`, width / 2, height / 2);
      ctx.restore();
    },
  };
  

  ChartJS.register(centerTextPlugin);

  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, bgcolor: 'background.default', minHeight: '100vh' }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
      <Typography variant="h4" fontWeight="bold">
        {t('signals.stats.header')}
      </Typography>
      <Box>
        {/* Weekly og Monthly */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Button
            onClick={() => setTimeframe('week')}
            variant={timeframe === 'week' ? 'contained' : 'outlined'}
          >
            {t('signals.stats.weekly')}
          </Button>
          <Button
            onClick={() => setTimeframe('month')}
            variant={timeframe === 'month' ? 'contained' : 'outlined'}
          >
            {t('signals.stats.monthly')}
          </Button>
        </Box>
        {/* Previous og Next */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button onClick={handlePrevious}>{t('signals.stats.previous')}</Button>
          <Typography>
            {timeframe === 'week'
              ? `${t('signals.stats.week')} ${getWeekNumber(currentDate)}, ${currentDate.getFullYear()}`
              : `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`}
          </Typography>
          <Button onClick={handleNext}>{t('signals.stats.next')}</Button>
        </Box>
      </Box>
    </Box>

{/* Overall Performance */}
<Grid container spacing={4} alignItems="stretch">
  {/* Summary til venstre */}
  <Grid item xs={12} md={4}>
    <Card sx={{ padding: 2, height: '100%' }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {/* Venstre side: Tekst */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              {t('signals.stats.summary')}
            </Typography>
            <Typography variant="body1" fontWeight="bold" color="textSecondary">
              {t('signals.stats.total_signals')}
            </Typography>
            <Typography variant="h3" fontWeight="bold" sx={{ mb: 2 }}>
              {overallSummary.totalSignals}
            </Typography>
            <Typography variant="body1" fontWeight="bold" color="textSecondary">
              {t('signals.stats.win_rate')}
            </Typography>
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
              {summary.winRate}%
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              {t('signals.stats.position_types')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '16px', mt: 1 }}>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {t('signals.stats.long')}
                </Typography>
                <Typography variant="h6">{summary.longSignals}</Typography>
                <Typography variant="body2">
                  {t('signals.stats.win_rate')}: {summary.longWinRate}%
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {t('signals.stats.short')}
                </Typography>
                <Typography variant="h6">{summary.shortSignals}</Typography>
                <Typography variant="body2">
                  {t('signals.stats.win_rate')}: {summary.shortWinRate}%
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Høyre side: Kakediagram */}
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ height: 200 }}>
              <Doughnut
                data={getChartData(overallSummary)}
                options={getDoughnutOptions(summary.winRate || 0)}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>

  {/* Providers til høyre */}
  <Grid item xs={12} md={8}>
    <Grid container spacing={2}>
      {providers.map((provider) => (
        <Grid item xs={12} sm={6} key={provider.group_id}>
          <Card sx={{ padding: 2, height: '100%' }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                {/* Venstre side: Kakediagram */}
                <Grid item xs={12} sm={5}>
                  <Box sx={{ height: 150 }}>
                    <Doughnut
                      data={getChartData(provider)}
                      options={getDoughnutOptions(provider.winRate || 0)}
                    />
                  </Box>
                </Grid>

                {/* Høyre side: Tekst */}
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                    {provider.group_name}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('signals.stats.position_types')}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {t('signals.stats.long_positions')}
                      </Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {provider.longPositions}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('signals.stats.win_rate')} {provider.longPositionsWinRate || 0}%
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {t('signals.stats.short_positions')}
                      </Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {provider.shortPositions}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('signals.stats.win_rate')} {provider.shortPositionsWinRate || 0}%
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('signals.stats.take_profit_levels')}
                  </Typography>
                  {['TP1', 'TP2', 'TP3'].map((label, index) => (
                    <Typography key={label} variant="body2">
                      {label}: {provider[`TP${index + 1}`]}
                    </Typography>
                  ))}
                  <Typography variant="body2">
                    {t('signals.stats.stop_loss')}: {provider.stopLoss}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Grid>
</Grid>

    </Box>
  );
};

// Helper function to calculate the week number
function getWeekNumber(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export default WeeklyStats;