import React, { useEffect, useState, useCallback } from 'react';
import {
Box,
Typography,
Grid,
Card,
CardContent,
CircularProgress,
Divider,
Table,
TableHead,
TableRow,
TableCell,
TableBody,
} from '@mui/material';
import { Person, Group, Assessment, People } from '@mui/icons-material';
import axios from 'axios';
import { useUserData } from '../../auth/UserData';

const AdminStatistics = () => {
  const { user } = useUserData();
  const [totalUsers, setTotalUsers] = useState(null);
  const [affiliateUsers, setAffiliateUsers] = useState(null);
  const [affiliateSpecificUsers, setAffiliateSpecificUsers] = useState(null);
  const [discordMembers, setDiscordMembers] = useState(null);
  const [newsDeskUsers, setNewsDeskUsers] = useState(null);
  const [activeUsersLast24h, setActiveUsersLast24h] = useState(null);
  const [newRegistrations, setNewRegistrations] = useState(null);
  const [clickStats, setClickStats] = useState([]); // For click statistics
  const [loading, setLoading] = useState(true);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const hasPermission = (permission) => user?.permissions?.includes(permission);

  const fetchStats = useCallback(async () => {
    setLoading(true);
    try {
      if (hasPermission('flockowner')) {
        const affiliateRes = await axios.get(`${BACKEND_URL}/api/stats/tradenation-clients`, {
          withCredentials: true,
        });
        setAffiliateUsers(affiliateRes.data.totalUsers);
      }

      if (hasPermission('flocktech')) {
        const totalUsersRes = await axios.get(`${BACKEND_URL}/api/stats/total-users`, {
          withCredentials: true,
        });
        setTotalUsers(totalUsersRes.data.totalUsers);

        const affiliateSpecificRes = await axios.get(
          `${BACKEND_URL}/api/stats/tradenation-clients-affiliate`,
          { withCredentials: true }
        );
        setAffiliateSpecificUsers(affiliateSpecificRes.data.affiliateUsers);

        const activeUsersRes = await axios.get(`${BACKEND_URL}/api/stats/active-users-24h`, {
          withCredentials: true,
        });
        setActiveUsersLast24h(activeUsersRes.data.activeUsers);

        const newRegistrationsRes = await axios.get(`${BACKEND_URL}/api/stats/new-registrations`, {
          withCredentials: true,
        });
        setNewRegistrations(newRegistrationsRes.data.newRegistrations);

        const clickStatsRes = await axios.get(`${BACKEND_URL}/api/stats/click-stats`, {
          withCredentials: true,
        });
        setClickStats(clickStatsRes.data);
      }

      const newsDeskRes = await axios.get(`${BACKEND_URL}/api/stats/newsdesk-users`, {
        withCredentials: true,
      });
      setNewsDeskUsers(newsDeskRes.data.newsDeskUsers);

      const discordRes = await axios.get(`${BACKEND_URL}/api/stats/discord/memberCount`, {
        withCredentials: true,
      });
      setDiscordMembers(discordRes.data.memberCount);
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  }, [BACKEND_URL]);

  useEffect(() => {
    fetchStats();
    const intervalId = setInterval(fetchStats, 10000);
    return () => clearInterval(intervalId);
  }, [fetchStats]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box my={4}>
      <Typography variant="h5" gutterBottom textAlign="center" sx={{ fontWeight: 'bold' }}>
        Platform Statistics Dashboard
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {/* TradeNation Statistics */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                TradeNation Statistics
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {hasPermission('flockowner') && affiliateUsers !== null && (
                <Box mb={2}>
                  <Typography variant="subtitle1">
                    <Group fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Total TradeNation Clients
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {affiliateUsers}
                  </Typography>
                </Box>
              )}
              {hasPermission('flocktech') && affiliateSpecificUsers !== null && (
                <Box>
                  <Typography variant="subtitle1">
                    <Assessment fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    TradeNation Clients 
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {affiliateSpecificUsers}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Platform Statistics */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Platform Statistics
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {hasPermission('flocktech') && totalUsers !== null && (
                <Box mb={2}>
                  <Typography variant="subtitle1">
                    <Person fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Total Website Users
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {totalUsers}
                  </Typography>
                </Box>
              )}
              {newsDeskUsers !== null && (
                <Box>
                  <Typography variant="subtitle1">
                    <People fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Active NewsDesk Users
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {newsDeskUsers}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Discord Statistics */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Discord Statistics
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {discordMembers !== null && (
                <Box>
                  <Typography variant="subtitle1">
                    <Group fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Discord Members
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {discordMembers}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
             {/* Active Users */}
             <Grid item xs={12} md={4}>
             <Card elevation={3} sx={{ borderRadius: 2 }}>
               <CardContent>
                 <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                   Active Users (Last 24h)
                 </Typography>
                 <Divider sx={{ mb: 2 }} />
                 {activeUsersLast24h !== null && (
                   <Box>
                     <Typography variant="subtitle1">
                       <Person fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                       Active Users
                     </Typography>
                     <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                       {activeUsersLast24h}
                     </Typography>
                   </Box>
                 )}
               </CardContent>
             </Card>
           </Grid>
   
           {/* New Registrations */}
           <Grid item xs={12} md={4}>
             <Card elevation={3} sx={{ borderRadius: 2 }}>
               <CardContent>
                 <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                   New Registrations (Last 24h)
                 </Typography>
                 <Divider sx={{ mb: 2 }} />
                 {newRegistrations !== null && (
                   <Box>
                     <Typography variant="subtitle1">
                       <Group fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                       New Registrations
                     </Typography>
                     <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                       {newRegistrations}
                     </Typography>
                   </Box>
                 )}
               </CardContent>
             </Card>
           </Grid>
           {/* Click Statistics */}
        <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Click Statistics
        </Typography>
        <Table>
          <TableHead>
          <TableRow>
            <TableCell>Link</TableCell>
            <TableCell>Total Clicks</TableCell>
            <TableCell>Unique Clicks</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
            {clickStats.map((stat) => (
              <TableRow key={stat.link}>
              <TableCell>{stat.link}</TableCell>
              <TableCell>{stat.totalClicks}</TableCell>
              <TableCell>{stat.uniqueClicks}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      </Grid>
    </Box>
  );
};

export default AdminStatistics;
