import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import axios from 'axios';

const AdminCourses = () => {
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [newCourse, setNewCourse] = useState({ name: '', description: '', categoryId: '' });
    const [openModal, setOpenModal] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [courseToDelete, setCourseToDelete] = useState(null);
    const [editingCourse, setEditingCourse] = useState(null);

    useEffect(() => {
        fetchCourses();
        fetchCategories();
    }, []);

    const fetchCourses = async () => {
        try {
            const response = await axios.get('/api/academy/admin/courses');
            setCourses(response.data);
        } catch (error) {
            console.error('Error fetching courses:', error.message);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/api/academy/admin/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error.message);
        }
    };

    const handleAddOrEditCourse = async () => {
        try {
            if (editingCourse) {
                // Update existing course
                await axios.put(`/api/academy/admin/courses/${editingCourse.id}`, newCourse);
            } else {
                // Add new course
                await axios.post('/api/academy/admin/courses', newCourse);
            }
            fetchCourses();
            setNewCourse({ name: '', description: '', categoryId: '' });
            setEditingCourse(null);
            setOpenModal(false);
        } catch (error) {
            console.error('Error saving course:', error.message);
        }
    };

    const handleEditCourse = (course) => {
        setEditingCourse(course);
        setNewCourse({
            name: course.name,
            description: course.description,
            categoryId: course.categoryId,
        });
        setOpenModal(true);
    };

    const handleOpenDeleteDialog = (course) => {
        setCourseToDelete(course);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setCourseToDelete(null);
    };

    const confirmDelete = async () => {
        if (courseToDelete) {
            try {
                await axios.delete(`/api/academy/admin/courses/${courseToDelete.id}`);
                fetchCourses();
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Error deleting course:', error.message);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Manage Courses
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                Add Course
            </Button>
            <Table sx={{ mt: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courses.map((course) => (
                        <TableRow key={course.id}>
                            <TableCell>{course.id}</TableCell>
                            <TableCell>{course.name}</TableCell>
                            <TableCell>{course.description}</TableCell>
                            <TableCell>
                                {categories.find((cat) => cat.id === course.categoryId)?.name || ''}
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleEditCourse(course)}
                                    sx={{ mr: 1 }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleOpenDeleteDialog(course)}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: 4,
                        width: 400,
                    }}
                >
                    <Typography variant="h6">
                        {editingCourse ? 'Edit Course' : 'Add New Course'}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Name"
                        value={newCourse.name}
                        onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={3}
                        value={newCourse.description}
                        onChange={(e) =>
                            setNewCourse({ ...newCourse, description: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <Select
                        fullWidth
                        value={newCourse.categoryId}
                        onChange={(e) =>
                            setNewCourse({ ...newCourse, categoryId: e.target.value })
                        }
                        displayEmpty
                        sx={{ mt: 2 }}
                    >
                        <MenuItem value="" disabled>
                            Select Category
                        </MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddOrEditCourse}
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                </Paper>
            </Modal>

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete the course "
                        {courseToDelete?.name}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={confirmDelete} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminCourses;
