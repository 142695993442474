import React from 'react';
import { Paper, Typography, TextField, Button } from '@mui/material';

export default function Settings() {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Settings</Typography>
      <TextField label="Site Title" fullWidth margin="normal" />
      <TextField label="Admin Email" fullWidth margin="normal" />
      <Button variant="contained" color="primary">Save</Button>
    </Paper>
  );
}
