import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import GoogleAnalytics from './components/GoogleAnalytics';
import Home from './pages/Home';
import Sentiment from './pages/Sentiment';
import Dashboard from './pages/Dashboard';
import Header from './components/Header';
import Footer from './components/Footer';
import NewsDeskPage from './pages/Newsdesk/NewsDeskPage';
import SignUp from './pages/SignUp';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/LogIn';
import AuthRedirect from './components/AuthRedirect';
import ForexNewsCalendar from './pages/Calendar';
import NewsletterPopup from './components/NewsLetterPopup';
import SignalStats from './pages/Signals/SignalDashboard';
import CookieConsentBanner from './components/CookieConsentBanner';
import TikTokPixel from './components/TiktokPixel';
import VerifyEmail from './pages/VerifyEmail';
import AdminLayout from './pages/Admin/AdminLayout';
import AdminDashboard from './pages/Admin/Dashboard';
import AdminUsers from './pages/Admin/Users';
import AdminSettings from './pages/Admin/Settings';
import axios from 'axios';
import { useUserData } from './auth/UserData';
import AnalyticsDashboard from './pages/Admin/Analytics/AnalyticsPanel';
import NewsletterDashboard from './pages/Admin/Newsletter/Newsletter';
import NewsletterDetails from './pages/Admin/Newsletter/NewsletterDetails';
import NewsletterSender from './pages/Admin/Newsletter/NewsletterCompose';
import NewsletterPreview from './pages/Admin/Newsletter/NewsletterPreview';
import NewsletterStatistics from './pages/Admin/Newsletter/NewsletterStatistics';
import Unsubscribe from './pages/Unsubscribe';
import AcademyHome from './pages/Academy/AcademyHome';
import CourseList from './pages/Academy/CourseList';
import CourseDetail from './pages/Academy/CourseDetails';
import AdminAcademy from './pages/Admin/Academy/Academy';
import QuizDetails from './pages/Academy/QuizDetails';

const trackEvent = async (event, details = {}) => {
  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stats/track-visit`, {
      page: event,
      details,
    });
  } catch (error) {
    console.error('Error tracking event:', error.message);
  }
};

function AppLayout({ children }) {
  const location = useLocation();
  const isNewsDeskPage = location.pathname === '/news-desk';

  const { user } = useUserData();

  // Track page views
  useEffect(() => {
    // Track Page Views
    trackEvent('PageView', { page: location.pathname, userId: user?.internalId || 'Guest' });
    
  }, [location, user]);
  
  // Track Clicks for Links and Buttons
  useEffect(() => {
    const handleClick = (event) => {
      const { tagName, innerText } = event.target;
  
      // Only log clicks for buttons and links
      if (tagName === 'BUTTON' || tagName === 'A') {
        trackEvent('Click', {
          tag: tagName,
          text: innerText?.slice(0, 50) || '', // Limit text length for logging
          userId: user?.internalId || 'Guest', // Log userId if available
        });
      }
    };
  
    document.addEventListener('click', handleClick);
  
    // Cleanup listener
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [user]);

  return (
    <>
      {!isNewsDeskPage && <Header />}
      {children}
      {!isNewsDeskPage && <Footer />}
    </>
  );
}

function App() {
  return (
    <>
      <GoogleAnalytics />
      <TikTokPixel />
      <NewsletterPopup />
      <CookieConsentBanner />
      <Routes>
        {/* Public routes with Header and Footer */}
        <Route
          path="*"
          element={
            <AppLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sentiment" element={<Sentiment />} />
                <Route path="/calendar" element={<ForexNewsCalendar />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/signals" element={<SignalStats />} />
                <Route path="/unsubscribe" element={<Unsubscribe />} />

                <Route path="/academy" element={<ProtectedRoute requiredPermissions={['flocktrader']}> <AcademyHome /> </ProtectedRoute>} /> 
                <Route path="/academy/category/:categoryId" element={<CourseList />} />
                <Route path="/academy/course/:courseId" element={<CourseDetail />} />
                <Route path="/academy/quiz/:courseId" element={<QuizDetails />} />


                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute requiredPermissions={['flocktrader']}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/news-desk" element={<NewsDeskPage />} />
                <Route path="*" element={<AuthRedirect />} />
              </Routes>
            </AppLayout>
          }
        />

        {/* Admin routes without Header and Footer */}
        <Route
          path="/dashboard/admin/*"
          element={
            <ProtectedRoute requiredPermissions={['flocktech']}>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="analytics" element={<AnalyticsDashboard />} />
          <Route path="users" element={<AdminUsers />} />
          <Route path="settings" element={<AdminSettings />} />
          <Route path="newsletter" element={ <NewsletterDashboard /> } />
          <Route path="newsletter/create" element={ <NewsletterSender />} />
          <Route path="newsletter/:id" element={<NewsletterDetails />} />
          <Route path="academy" element={<AdminAcademy />} />
          
        </Route>
      </Routes>
    </>
  );
}

export default App;
