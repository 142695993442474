import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Modal,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { htmlToText } from 'html-to-text';

const NewsletterSender = () => {
  const [subject, setSubject] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [testEmails, setTestEmails] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [plainTextContent, setPlainTextContent] = useState('');


  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Hent maler fra backend
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/newsletter/newsletter-templates`);
      setTemplates(response.data);
    } catch (error) {
      console.error('Failed to load templates:', error.message);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [BACKEND_URL]);

  const handleCreateTemplateDialog = () => {
    setSelectedTemplate(null); // Clear selected template
    setSubject('');
    setHtmlContent('');
    setTemplateName('');
    setDialogOpen(true);
  };

  const handleCreateNewTemplate = async () => {
    if (!templateName || !subject || !htmlContent) {
      setError('Please fill in all fields.');
      return;
    }
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/newsletter/newsletter-templates`,
        { name: templateName, subject, htmlContent },
        { withCredentials: true }
      );
      setSuccess('Template created successfully.');
      setTemplates((prev) => [...prev, response.data]);
      setTemplateName('');
      setSubject('');
      setHtmlContent('');
    } catch (err) {
      setError('Failed to create template.');
    } finally {
      setDialogOpen(false);
    }
  };

  const handleSendNewsletter = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      await axios.post(
        `${BACKEND_URL}/api/newsletter/send-newsletter`,
        { subject, htmlContent, plainTextContent },
        { withCredentials: true }
      );
      setSuccess('Newsletter sent successfully!');
    } catch (err) {
      setError('Failed to send newsletter.');
    } finally {
      setLoading(false);
    }
  };

  const handleSendTestEmail = async () => {
    if (!testEmails) {
      setError('Please enter at least one email address.');
      return;
    }
  
    const emailList = testEmails.split(',').map((email) => email.trim()).join(','); // Kombiner til streng
    if (!emailList.length) {
      setError('Invalid email addresses.');
      return;
    }
  
    setLoading(true);
    setError('');
    setSuccess(false);
  
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/newsletter/send-test`,
        { subject, htmlContent, plainTextContent, testEmails: emailList }, // Bruker testEmails som en streng
        { withCredentials: true }
      );
      setSuccess(`Test email sent successfully to: ${emailList}`);
    } catch (err) {
      setError('Failed to send test email.');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTemplate = async () => {
    if (!selectedTemplate || !subject || !htmlContent) {
      setError('Subject and content are required.');
      return;
    }
    setLoading(true);
    try {
      await axios.put(`${BACKEND_URL}/api/newsletter/newsletter-templates/${selectedTemplate.id}`, {
        name: selectedTemplate.name,
        subject,
        htmlContent,
      });
      setSuccess('Template updated successfully.');
      fetchTemplates();
    } catch (err) {
      setError('Failed to save template.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteTemplate = async () => {
    if (!selectedTemplate) {
      setError('No template selected.');
      return;
    }
    setLoading(true);
    try {
      await axios.delete(`${BACKEND_URL}/api/newsletter/newsletter-templates/${selectedTemplate.id}`);
      setSuccess('Template deleted successfully.');
      setSelectedTemplate(null);
      setSubject('');
      setHtmlContent('');
      fetchTemplates();
    } catch (err) {
      setError('Failed to delete template.');
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateSelect = (template) => {
    setSubject(template.subject);
    setHtmlContent(template.htmlContent);
    setSelectedTemplate(template);
    setAnchorEl(null); // Lukk menyen
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const generatePlainText = (html) => {
    return htmlToText(html, {
      wordwrap: 130, // Set line wrapping for better readability
    });
  };
  
  // Automatically generate plain text when HTML content changes
  useEffect(() => {
    setPlainTextContent(generatePlainText(htmlContent));
  }, [htmlContent]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Send Newsletter
      </Typography>

      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
      <Button variant="outlined" onClick={handleOpenMenu}>
        Choose Template
      </Button>
      {!selectedTemplate ? (
        <Button variant="contained" color="primary" onClick={handleCreateTemplateDialog}>
          Create New Template
        </Button>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={handleSaveTemplate} disabled={loading}>
            Save Template
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleDeleteTemplate} sx={{ ml: 2 }} disabled={loading}>
            Delete Template
          </Button>
        </>
      )}
    </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {templates.length > 0 ? (
          templates.map((template) => (
            <MenuItem
              key={template.id}
              onClick={() => handleTemplateSelect(template)}
            >
              {template.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No templates available</MenuItem>
        )}
      </Menu>

      <TextField
        label="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Box mt={2}>
      <Editor
      apiKey="ccol78ntid2nnnsti0in1cj0nok8rble3zqu4trm3u2co9nj"
      value={htmlContent}
      init={{
        height: 500,
        menubar: true,
        plugins: 'link image code lists table code',
        valid_elements: '*[*]', // Allow all elements and attributes
        extended_valid_elements: 'html,body',
        toolbar:
          'undo redo | bold italic underline | fontselect fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | link image | insertVariableButton | code | buttonInsertButton',
          relative_urls: false, // Bruk absolutt URL
          remove_script_host: false, // Behold domenet i URL-en
          document_base_url: 'https://flocktrade.com/', // Basen for relative URL-er
        setup: (editor) => {
          // Custom button to insert variables
          editor.ui.registry.addMenuButton('insertVariableButton', {
            text: 'Insert Variable',
            fetch: (callback) => {
              const items = [
                { type: 'menuitem', text: 'First Name', onAction: () => editor.insertContent('{firstname}') },
                { type: 'menuitem', text: 'Name', onAction: () => editor.insertContent('{name}') },
              ];
              callback(items);
            },
          });
    
          // Custom button to insert a styled button
          editor.ui.registry.addButton('buttonInsertButton', {
            text: 'Insert Button',
            icon: 'plus',
            onAction: () => {
              editor.windowManager.open({
                title: 'Insert Button',
                body: {
                  type: 'panel',
                  items: [
                    { type: 'input', name: 'text', label: 'Button Text' },
                    { type: 'input', name: 'url', label: 'Button URL' },
                    {
                      type: 'colorinput',
                      name: 'color',
                      label: 'Button Color',
                      format: 'hex',
                    },
                  ],
                },
                buttons: [
                  {
                    type: 'submit',
                    text: 'Insert',
                  },
                  {
                    type: 'cancel',
                    text: 'Cancel',
                  },
                ],
                onSubmit: (api) => {
                  const data = api.getData();
                  editor.insertContent(
                    `<a href="${data.url}" style="padding: 10px 20px; background-color: ${data.color}; color: white; text-decoration: none; border-radius: 5px;">${data.text}</a>`
                  );
                  api.close();
                },
              });
            },
          });
        },
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            const formData = new FormData();
            formData.append('file', blobInfo.blob());
            
            // Bytt til din backend-endepunkt for bildeopplasting
            const response = await axios.post(`${BACKEND_URL}/api/newsletter/upload-image`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
    
            if (response.data?.url) {
              success(response.data.url); // Returner den fullstendige URL-en
            } else {
              failure('Failed to upload image');
            }
          } catch (error) {
            console.error('Image upload failed:', error);
            failure('Image upload failed');
          }
        },
        images_upload_url: `${BACKEND_URL}/api/newsletter/upload-image`, // Kan brukes som fallback
        fontsize_formats: '6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt',
      }}
      onEditorChange={(content) => setHtmlContent(content)}
    />
      </Box>

      <Box mt={2}>
        <Button
          variant="contained"
          onClick={handleSendNewsletter}
          disabled={loading || !subject || !htmlContent}
        >
          {loading ? 'Sending...' : 'Send Newsletter'}
        </Button>
        <Button
          onClick={() => setPreviewOpen(true)}
          sx={{ ml: 2 }}
          variant="outlined"
        >
          Preview
        </Button>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Send Test Email
        </Typography>
        <TextField
          label="Test Emails (comma-separated)"
          value={testEmails}
          onChange={(e) => setTestEmails(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="outlined"
          onClick={handleSendTestEmail}
          disabled={loading || !subject || !htmlContent}
        >
          {loading ? 'Sending Test...' : 'Send Test Email'}
        </Button>
      </Box>

      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: '#fff',
          borderRadius: 2,
          width: '90%',
          maxWidth: '800px',
          margin: '50px auto',
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Newsletter Preview
        </Typography>
    
        <Typography variant="subtitle1" gutterBottom>
          HTML Content:
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    
        <Typography variant="subtitle1" gutterBottom>
          Plain Text Content:
        </Typography>
        <pre style={{ whiteSpace: 'pre-wrap', background: '#f0f0f0', padding: '10px' }}>
          {plainTextContent}
        </pre>
      </Box>
    </Modal>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Create New Template</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Template Name"
            type="text"
            fullWidth
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateNewTemplate} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {success && (
        <Snackbar
          open={!!success}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError('')}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default NewsletterSender;
