import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CourseDetails = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/academy/courses/${courseId}`);
        setCourseDetails(response.data.course);

        // Legg til quiz-seksjon i seksjonslisten
        const sectionsWithQuiz = [
          ...response.data.sections,
          {
            id: 'quiz',
            name: 'Quiz',
            description: 'Test kunnskapen din etter dette kurset.',
            content: '',
          },
        ];
        setSections(sectionsWithQuiz);
      } catch (error) {
        console.error('Error fetching course details:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [courseId]);

  const handleCompleteSection = async () => {
    const sectionId = sections[currentSectionIndex].id;

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/academy/progress`, {
        userId: 1,
        sectionId,
        isCompleted: true,
      });
      if (currentSectionIndex < sections.length - 1) {
        setCurrentSectionIndex((prev) => prev + 1);
      }
    } catch (error) {
      console.error('Error updating progress:', error.message);
    }
  };

  const handlePrevSection = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex((prev) => prev - 1);
    }
  };

  const handleStartQuiz = () => {
    navigate(`/academy/quiz/${courseId}`);
  };

  if (loading) return <CircularProgress />;

  return (
    <Box p={4}>
      <Button onClick={() => navigate(-1)} variant="outlined" sx={{ mb: 2 }}>
        Tilbake til kurslisten
      </Button>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              {courseDetails.name}
            </Typography>
            <Stepper activeStep={currentSectionIndex} orientation="vertical">
              {sections.map((section) => (
                <Step key={section.id}>
                  <StepLabel>
                    <Typography variant="h6" fontWeight="bold">
                      {section.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {section.description}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="h5" fontWeight="bold" mb={2}>
              {sections[currentSectionIndex].name}
            </Typography>
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: sections[currentSectionIndex].content }}
              sx={{ mb: 4 }}
            />
            <Divider sx={{ mb: 4 }} />
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button
                onClick={handlePrevSection}
                disabled={currentSectionIndex === 0}
                variant="outlined"
              >
                Forrige
              </Button>
              {currentSectionIndex < sections.length - 1 ? (
                <Button onClick={handleCompleteSection} variant="contained">
                  Neste
                </Button>
              ) : sections[currentSectionIndex].id === 'quiz' ? (
                <Button onClick={handleStartQuiz} variant="contained" color="success">
                  Start Quiz
                </Button>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CourseDetails;
