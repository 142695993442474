import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import axios from 'axios';

const AdminQuizzes = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [sections, setSections] = useState([]);
    const [newQuiz, setNewQuiz] = useState({ title: '', description: '', sectionId: '' });
    const [openModal, setOpenModal] = useState(false);
    const [editingQuiz, setEditingQuiz] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [quizToDelete, setQuizToDelete] = useState(null);

    useEffect(() => {
        fetchQuizzes();
        fetchSections();
    }, []);

    const fetchQuizzes = async () => {
        try {
            const response = await axios.get('/api/academy/admin/quizzes');
            setQuizzes(response.data);
        } catch (error) {
            console.error('Error fetching quizzes:', error.message);
        }
    };

    const fetchSections = async () => {
        try {
            const response = await axios.get('/api/academy/admin/sections');
            setSections(response.data);
        } catch (error) {
            console.error('Error fetching sections:', error.message);
        }
    };

    const handleAddOrEditQuiz = async () => {
        try {
            if (editingQuiz) {
                // Update existing quiz
                await axios.put(`/api/academy/admin/quizzes/${editingQuiz.id}`, newQuiz);
            } else {
                // Add new quiz
                await axios.post('/api/academy/admin/quizzes', newQuiz);
            }
            fetchQuizzes();
            setNewQuiz({ title: '', description: '', sectionId: '' });
            setEditingQuiz(null);
            setOpenModal(false);
        } catch (error) {
            console.error('Error saving quiz:', error.message);
        }
    };

    const handleEditQuiz = (quiz) => {
        setEditingQuiz(quiz);
        setNewQuiz({
            title: quiz.title,
            description: quiz.description,
            sectionId: quiz.sectionId,
        });
        setOpenModal(true);
    };

    const handleOpenDeleteDialog = (quiz) => {
        setQuizToDelete(quiz);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setQuizToDelete(null);
    };

    const confirmDelete = async () => {
        if (quizToDelete) {
            try {
                await axios.delete(`/api/academy/admin/quizzes/${quizToDelete.id}`);
                fetchQuizzes();
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Error deleting quiz:', error.message);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Manage Quizzes
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                Add Quiz
            </Button>
            <Table sx={{ mt: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Section</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quizzes.map((quiz) => (
                        <TableRow key={quiz.id}>
                            <TableCell>{quiz.id}</TableCell>
                            <TableCell>{quiz.title}</TableCell>
                            <TableCell>{quiz.description}</TableCell>
                            <TableCell>
                                {sections.find((section) => section.id === quiz.sectionId)?.name ||
                                    ''}
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleEditQuiz(quiz)}
                                    sx={{ mr: 1 }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleOpenDeleteDialog(quiz)}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: 4,
                        width: 400,
                    }}
                >
                    <Typography variant="h6">
                        {editingQuiz ? 'Edit Quiz' : 'Add New Quiz'}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Title"
                        value={newQuiz.title}
                        onChange={(e) => setNewQuiz({ ...newQuiz, title: e.target.value })}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={3}
                        value={newQuiz.description}
                        onChange={(e) =>
                            setNewQuiz({ ...newQuiz, description: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <Select
                        fullWidth
                        value={newQuiz.sectionId}
                        onChange={(e) =>
                            setNewQuiz({ ...newQuiz, sectionId: e.target.value })
                        }
                        displayEmpty
                        sx={{ mt: 2 }}
                    >
                        <MenuItem value="" disabled>
                            Select Section
                        </MenuItem>
                        {sections.map((section) => (
                            <MenuItem key={section.id} value={section.id}>
                                {section.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddOrEditQuiz}
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                </Paper>
            </Modal>

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete the quiz "
                        {quizToDelete?.title}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={confirmDelete} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminQuizzes;
