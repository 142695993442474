import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Container,
  Box,
  Button,
  Switch,
  Tooltip,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTheme } from '../context/ThemeContext';
import serverLogger from '../utils/ServerLogger';
import { useUserData } from '../auth/UserData';
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";



const Header = () => {
  const { t } = useTranslation(); // Oversettelsesfunksjon
  const { startAuth, endAuth, refreshUserData } = useUserData();
  const { darkMode, toggleDarkMode } = useTheme();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAvatar, setUserAvatar] = useState('/FlockTradeAvatar.png');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const DISCORD_AUTH_URL = `${process.env.REACT_APP_BACKEND_URL}/auth/discord`;
  const navigate = useNavigate();

  const pages = [
    { name: t("header.home"), path: '/' },
    { name: t("header.sentiment"), path: '/sentiment' },
    { name: t("header.calendar"), path: '/calendar' },
    { name: t("header.signals"), path: '/signals' },
  ];

  const settings = [
    { name: t("header.dashboard"), action: 'dashboard' },
    {
      name: t("header.tradingTools"),
      children: [
        { name: t("header.newsDesk"), action: 'news-desk' },
        { name: t("header.signalsStatistics"), action: 'signals' },
      ],
    },
    { name: t("header.logout"), action: 'logout' },
  ];


  useEffect(() => {
    let isMounted = true; // To avoid state updates on unmounted components
  
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/status`, { withCredentials: true });
        const { authenticated, user } = response.data;
  
        if (isMounted) {
          if (authenticated) {
            setIsAuthenticated(true);
            const avatarUrl = user.avatar
              ? `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.png`
              : '/FlockTradeAvatarGB.jpg';
            setUserAvatar(avatarUrl);
          } else {
            setIsAuthenticated(false);
            setUserAvatar('/FlockTradeAvatar.png');
          }
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };
  
    checkAuthStatus();
  
    const handleMessage = (event) => {
      if (event.origin === process.env.REACT_APP_BACKEND_URL && event.data === 'loggedIn') {
        serverLogger.log('info', "Login message received.");
        setIsAuthenticated(true);
  
        // Redirect to /dashboard only if not already there
        if (window.location.pathname !== '/dashboard') {
          navigate('/dashboard', { replace: true });
        }
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      isMounted = false; // Cleanup
      window.removeEventListener('message', handleMessage);
    };
  }, [navigate]);

 

  const handleLogin = () => {
    startAuth(); // Indicate that authentication is in progress

    const width = 500;
    const height = 700;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const popup = window.open(
      DISCORD_AUTH_URL,
      'Discord Login',
      `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=yes,status=no`
    );

    if (!popup) {
      alert('Popup blocked! Please allow popups for this website.');
      endAuth(); // End authentication process if popup is blocked
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          endAuth(); // Mark the authentication process as completed
          refreshUserData(); // Refresh user data after the popup closes
        }
      }, 500);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, { withCredentials: true });
      setIsAuthenticated(false);
      setUserAvatar('/FlockTradeAvatar.png');
      navigate('/');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const toggleSubmenu = (name) => {
    setExpandedMenu(expandedMenu === name ? null : name);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingAction = (action) => {
    if (action === 'logout') {
      handleLogout();
    } else if (action === 'news-desk') {
      const popup = window.open(
        '/news-desk',
        'NewsDesk',
        'width=1500,height=600,scrollbars=no,resizable=yes'
      );
      if (!popup) {
        alert('Popup blocked! Please allow popups for this website.');
      }
      handleCloseUserMenu();
    } else {
      handleCloseUserMenu();
      navigate(`/${action}`);
    }
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((page) => (
          <ListItem 
            key={page.name} 
            component={Link} 
            to={page.path} 
            button="true"
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
        {isAuthenticated ? (
          <ListItem 
            button="true" 
            onClick={handleLogout} 
            component="div"
          >
            <ListItemText primary={t("header.logout")} />
          </ListItem>
        ) : (
          <ListItem 
            button="true" 
            onClick={handleLogin} 
            component="div"
          >
            <ListItemText primary={t("header.login")} />
          </ListItem>
        )}
      </List>
    </Box>
  );


  return (
    <AppBar position="sticky" color="primary" sx={{
      bgcolor: 'rgba(10, 25, 41, 0.8)', // Mørk blå med 80% opasitet
      backdropFilter: 'blur(10px)',     // Gir en blurry glass-effekt
      boxShadow: 'none',                // Fjern skygge for en ren stil
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <IconButton
              size="large"
              color="inherit"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerContent}
            </Drawer>
          </Box>

          {/* Logo in the center for mobile and left-aligned for desktop */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
          <Avatar
            alt="FlockTrade Logo"
            src="/FlockTradeAvatar.png"
            sx={{
              width: 50, // Adjust size to fit AppBar
              height: 50,
              transform: 'scale(2)', // Scale it up visually
            }}
          />
          </Link>
        </Box>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              flexGrow: 1,
              textDecoration: 'none',
              marginLeft: -10,
              color: 'inherit',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            FlockTrade
          </Typography>

          {/* Desktop Navigation */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                sx={{ my: 2, color: 'white' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* User Menu and Avatar */}
          <Box sx={{ flexGrow: 0 }}>
          {isAuthenticated ? (
            <>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User Avatar" src={userAvatar} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) =>
                  setting.children ? (
                    <React.Fragment key={setting.name}>
                      <MenuItem onClick={() => toggleSubmenu(setting.name)}>
                        <ListItemText primary={setting.name} />
                        {expandedMenu === setting.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </MenuItem>
                      <Collapse in={expandedMenu === setting.name} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {setting.children.map((child) => (
                            <MenuItem
                              key={child.name}
                              onClick={() => handleSettingAction(child.action)}
                              sx={{ pl: 4 }}
                            >
                              <Typography textAlign="left">{child.name}</Typography>
                            </MenuItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <MenuItem key={setting.name} onClick={() => handleSettingAction(setting.action)}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </>
          ) : (
            <Button color="inherit" onClick={handleLogin}>
              {t("header.login")}
            </Button>
          )}
        </Box>

              {/* Dark/Light Mode */}
              <MaterialUISwitch
              sx={{ m: 1 }}
              checked={darkMode}
              onChange={toggleDarkMode}
            />
            <LanguageSwitcher />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
