import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Grid,
  Chip,
  Divider,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CourseList = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/academy/category/${categoryId}`);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [categoryId]);

  const getButtonText = (course) => {
    if (course.completed) {
      return 'Take Again';
    } else if (course.started) {
      return 'Continue';
    } else {
      return 'Start Kurs';
    }
  };

  const getButtonColor = (course) => {
    if (course.completed) {
      return 'secondary';
    } else if (course.started) {
      return 'primary';
    } else {
      return 'success';
    }
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  return (
    <Box p={4}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Courses in Selected Category
      </Typography>
      <Divider sx={{ mb: 4 }} />

      {/* Course Grid */}
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardContent>
                {/* Course Title */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {course.name}
                </Typography>
                {/* Course Description */}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {course.description}
                </Typography>
                {/* Duration and Status */}
                <Box display="flex" justifyContent="space-between" mt={2} alignItems="center">
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">{course.duration || 'Unknown'} minutes</Typography>
                  </Box>
                  <Chip
                    label={
                      course.completed
                        ? 'Completed'
                        : course.started
                        ? 'In Progress'
                        : 'Not Started'
                    }
                    color={course.completed ? 'success' : course.started ? 'info' : 'default'}
                    size="small"
                    icon={course.completed ? <DoneIcon /> : null}
                  />
                </Box>
              </CardContent>
              {/* Course Actions */}
              <CardActions sx={{ mt: 'auto', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color={getButtonColor(course)}
                  onClick={() => navigate(`/academy/course/${course.id}`)}
                >
                  {getButtonText(course)}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseList;
