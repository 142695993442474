import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Tabs,
    Tab,
    Paper,
    CircularProgress,
    Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NewsletterStatistics from './NewsletterStatistics';

const NewsletterDetails = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [newsletter, setNewsletter] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`/api/analytics/newsletter-analytics/${id}`);
                setNewsletter(response.data);
            } catch (error) {
                console.error('Error fetching newsletter:', error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchDetails();
    }, [id]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (!newsletter) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    Feil ved lasting av nyhetsbrev. Vennligst prøv igjen senere.
                </Typography>
            </Box>
        );
    }

    return (
        <Box p={4}>
            <Box mb={4}>
                <Typography variant="h4" fontWeight="bold">
                    {newsletter.subject || 'Ukjent emne'}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Sendt: {newsletter.sent_at ? new Date(newsletter.sent_at).toLocaleString() : 'Ingen data'}
                </Typography>
            </Box>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Vis Kampanje" />
                <Tab label="Statistikk" />
            </Tabs>

            <Divider sx={{ my: 2 }} />

            {activeTab === 0 && <PreviewTab htmlContent={newsletter.html_content} />}
            {activeTab === 1 && <NewsletterStatistics newsletter={newsletter} />}
        </Box>
    );
};

const PreviewTab = ({ htmlContent }) => {
    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" mb={2}>
                Forhåndsvisning av nyhetsbrev
            </Typography>
            <div
                dangerouslySetInnerHTML={{ __html: htmlContent || '<p>Ingen forhåndsvisning tilgjengelig</p>' }}
                style={{
                    border: '1px solid #ccc',
                    padding: '16px',
                    borderRadius: '8px',
                }}
            />
        </Paper>
    );
};

export default NewsletterDetails;
