import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Badge,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { useUserData } from '../auth/UserData';
import AdminPanel from '../components/AdminPanel';
import WebServerStats from './Dashboard/webServerStats';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BarChartIcon from '@mui/icons-material/BarChart'; // Importer nødvendige Material-UI-ikoner
import AdminStatistics from '../components/AdminStatistics';
import { useNavigate } from 'react-router-dom';
import ChangelogPopup from '../components/ChangelogPopup';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation(); // Oversettelsesfunksjon
  const { user, isLoading } = useUserData();
  const navigate = useNavigate();
  
  const CURRENT_VERSION = '1.0.2';
  const CHANGELOG = `

   <ul>
    <li>
      Newsdesk: Added sound, you can choose sound for new incomming news.
    </li>
    <li>Bug fixed: Fixed darkmode bug on newsdesk</li>
    <li>
      Global: Added multilanguage for Norwegian and English
    </li>
  </ul>

  <ul>
    <li>
      Bug fixed: ForexNews on newsdesk, autoscroll down to nextevent. and live updates.
    </li>
    <li>
    New style: sentiment got an makeover, added timeframe on the graph
    </li>
  </ul>

  <ul>
    <li>New feature: Dashboard redesign.</li>
    <li>Added Weekly and monthly Stats navigation.</li>
    <li>Improved performance and fixed bugs.</li>
     
    <li>New feature: Sentiment Analysis.</li>
    <li>Added Forex Calendar for better planning.</li>
    <li>Improved NewsDesk:
      <ul>
        <li>Added Ignore Source feature.</li>
        <li>Time Zone adjustment functionality.</li>
      </ul>
    </li>
    <li>Enhanced Signal Statistics Overview.</li>
  </ul>

`;

const [showChangelog, setShowChangelog] = useState(false);
const [showBadge, setShowBadge] = useState(false);

useEffect(() => {
  const storedVersion = localStorage.getItem('latestVersion');
  if (storedVersion !== CURRENT_VERSION) {
    setShowChangelog(true);
    setShowBadge(true);
  }
}, []);

const handleOpenChangelog = () => {
  setShowChangelog(true);
  setShowBadge(false);
  localStorage.setItem('latestVersion', CURRENT_VERSION);
};

const handleCloseChangelog = () => {
  setShowChangelog(false);
  localStorage.setItem('latestVersion', CURRENT_VERSION);
};

const hasPermission = (permission) => user?.permissions?.includes(permission);

if (isLoading) {
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    </Container>
  );
}

if (!user || !user.permissions) {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh', textAlign: 'center' }}>
      <Typography variant="h4" color="error" gutterBottom>
        {t('dashboard.accessDenied.title')}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {t('dashboard.accessDenied.message')}
      </Typography>
    </Container>
  );
}

return (
  <Container maxWidth="lg">
    {/* Changelog Popup */}
    {showChangelog && (
      <ChangelogPopup
        version={CURRENT_VERSION}
        changelog={CHANGELOG}
        onClose={handleCloseChangelog}
      />
    )}

    {/* Dashboard Header */}
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <Typography variant="h4">{t('dashboard.title')}</Typography>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {t('dashboard.version', { version: CURRENT_VERSION })}
        </Typography>
        <Badge color="error" variant="dot" invisible={!showBadge}>
          <Button size="small" onClick={handleOpenChangelog}>
            {t('dashboard.whatsNew')}
          </Button>
        </Badge>
      </Box>
    </Box>

{/* Trader Tools */}
<Box mt={4}>
  <Grid container spacing={3}>
    {hasPermission('flocktrader') && (
      <Grid item xs={12} sm={6}>
        <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
          <CardContent>
            {/* Header */}
            <Box display="flex" alignItems="center" gap={2} mb={2}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                {t('dashboard.traderTools.title')}
              </Typography>
            </Box>
            {/* Description */}
            <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
              {t('dashboard.traderTools.description')}
            </Typography>
            {/* Buttons */}
            <Box display="flex" gap={2} flexWrap="wrap">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const popup = window.open(
                    '/news-desk',
                    'NewsDesk',
                    'width=1500,height=600,scrollbars=no,resizable=yes'
                  );
                  if (!popup) {
                    alert('Popup blocked! Please allow popups for this website.');
                  }
                }}
                startIcon={<NewspaperIcon />} // Material-UI ikon
                sx={{ textTransform: 'capitalize', flex: 1 }}
              >
                {t('dashboard.traderTools.newsDesk')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate('/signals')}
                startIcon={<BarChartIcon />} // Material-UI ikon
                sx={{ textTransform: 'capitalize', flex: 1 }}
              >
                {t('dashboard.traderTools.signalStatistics')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    )}

        {hasPermission('flockadmin') && (
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">{t('dashboard.adminTools.title')}</Typography>
                <AdminPanel />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>

    {/* Server & Bot Statistics */}
    <WebServerStats />
  </Container>
);
};

export default Dashboard;