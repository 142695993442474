import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
} from '@mui/material';
import axios from 'axios';

const AdminQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [newQuestion, setNewQuestion] = useState({
        questionText: '',
        content: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctOption: '',
        quizId: '',
        image: null, // For image upload
        videoUrl: '', // For video URL
    });
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        fetchQuestions();
        fetchQuizzes();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await axios.get('/api/academy/admin/questions');
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions:', error.message);
        }
    };

    const fetchQuizzes = async () => {
        try {
            const response = await axios.get('/api/academy/admin/quizzes');
            setQuizzes(response.data);
        } catch (error) {
            console.error('Error fetching quizzes:', error.message);
        }
    };

    const handleAddOrEditQuestion = async () => {
        const formData = new FormData();
        formData.append('questionText', newQuestion.questionText);
        formData.append('content', newQuestion.content);
        formData.append('optionA', newQuestion.optionA);
        formData.append('optionB', newQuestion.optionB);
        formData.append('optionC', newQuestion.optionC);
        formData.append('optionD', newQuestion.optionD);
        formData.append('correctOption', newQuestion.correctOption);
        formData.append('quizId', newQuestion.quizId);
        if (newQuestion.image) {
            formData.append('image', newQuestion.image);
        }
        formData.append('videoUrl', newQuestion.videoUrl);

        try {
            if (newQuestion.id) {
                // Edit existing question
                await axios.put(`/api/academy/admin/questions/${newQuestion.id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } else {
                // Add new question
                await axios.post('/api/academy/admin/questions', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            }
            fetchQuestions();
            handleCloseModal();
        } catch (error) {
            console.error('Error saving question:', error.message);
        }
    };

    const handleDeleteQuestion = async (id) => {
        try {
            await axios.delete(`/api/academy/admin/questions/${id}`);
            fetchQuestions();
        } catch (error) {
            console.error('Error deleting question:', error.message);
        }
    };

    const handleOpenModal = (question = null) => {
        setNewQuestion(
            question || {
                questionText: '',
                content: '',
                optionA: '',
                optionB: '',
                optionC: '',
                optionD: '',
                correctOption: '',
                quizId: '',
                image: null,
                videoUrl: '',
            }
        );
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Manage Questions
            </Typography>
            <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
                Add Question
            </Button>
            <Table sx={{ mt: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Quiz</TableCell>
                        <TableCell>Correct Option</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions.map((question) => (
                        <TableRow key={question.id}>
                            <TableCell>{question.id}</TableCell>
                            <TableCell>{question.questionText}</TableCell>
                            <TableCell>
                                {quizzes.find((quiz) => quiz.id === question.quizId)?.title || ''}
                            </TableCell>
                            <TableCell>{question.correctOption}</TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleOpenModal(question)}
                                    sx={{ mr: 1 }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteQuestion(question.id)}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: 4,
                        width: 400,
                    }}
                >
                    <Typography variant="h6">
                        {newQuestion.id ? 'Edit Question' : 'Add New Question'}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Question"
                        value={newQuestion.questionText}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, questionText: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Content"
                        multiline
                        rows={4}
                        value={newQuestion.content}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, content: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Option A"
                        value={newQuestion.optionA}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, optionA: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Option B"
                        value={newQuestion.optionB}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, optionB: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Option C"
                        value={newQuestion.optionC}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, optionC: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Option D"
                        value={newQuestion.optionD}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, optionD: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Correct Option (A, B, C, D)"
                        value={newQuestion.correctOption}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, correctOption: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <Select
                        fullWidth
                        value={newQuestion.quizId}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, quizId: e.target.value })
                        }
                        displayEmpty
                        sx={{ mt: 2 }}
                    >
                        <MenuItem value="" disabled>
                            Select Quiz
                        </MenuItem>
                        {quizzes.map((quiz) => (
                            <MenuItem key={quiz.id} value={quiz.id}>
                                {quiz.title}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        fullWidth
                        label="Video URL"
                        value={newQuestion.videoUrl}
                        onChange={(e) =>
                            setNewQuestion({ ...newQuestion, videoUrl: e.target.value })
                        }
                        sx={{ mt: 2 }}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2 }}
                    >
                        Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={(e) =>
                                setNewQuestion({ ...newQuestion, image: e.target.files[0] })
                            }
                        />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddOrEditQuestion}
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                </Paper>
            </Modal>
        </Box>
    );
};

export default AdminQuestions;
