import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import axios from 'axios';

const AdminCategories = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newCategory, setNewCategory] = useState({ name: '', description: '' });
    const [openModal, setOpenModal] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/api/academy/admin/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error.message);
        }
    };

    const handleAddOrEditCategory = async () => {
        try {
            if (selectedCategory) {
                // Edit existing category
                await axios.put(`/api/academy/admin/categories/${selectedCategory.id}`, newCategory);
            } else {
                // Add new category
                await axios.post('/api/academy/admin/categories', newCategory);
            }
            fetchCategories();
            setOpenModal(false);
            setNewCategory({ name: '', description: '' });
            setSelectedCategory(null);
        } catch (error) {
            console.error('Error saving category:', error.message);
        }
    };

    const handleDeleteCategory = async () => {
        try {
            if (selectedCategory) {
                await axios.delete(`/api/academy/admin/categories/${selectedCategory.id}`);
                fetchCategories();
                setDeleteConfirm(false);
                setSelectedCategory(null);
            }
        } catch (error) {
            console.error('Error deleting category:', error.message);
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Manage Categories
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setNewCategory({ name: '', description: '' });
                    setSelectedCategory(null);
                    setOpenModal(true);
                }}
            >
                Add Category
            </Button>
            <Table sx={{ mt: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((category) => (
                        <TableRow key={category.id}>
                            <TableCell>{category.id}</TableCell>
                            <TableCell>{category.name}</TableCell>
                            <TableCell>{category.description}</TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setSelectedCategory(category);
                                        setNewCategory({ name: category.name, description: category.description });
                                        setOpenModal(true);
                                    }}
                                    sx={{ mr: 1 }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setSelectedCategory(category);
                                        setDeleteConfirm(true);
                                    }}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Add/Edit Category Dialog */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>{selectedCategory ? 'Edit Category' : 'Add Category'}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Name"
                        value={newCategory.name}
                        onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={3}
                        value={newCategory.description}
                        onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddOrEditCategory}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete the category "{selectedCategory?.name}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirm(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteCategory}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminCategories;
