import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import {  Delete as DeleteIcon, Menu as MenuIcon } from '@mui/icons-material';
import axios from 'axios';
import { initializeWebSocket, addWebSocketListener, removeWebSocketListener } from '../../services/websocketService';
import { useUserData } from '../../auth/UserData';
import { useTheme } from '../../context/ThemeContext';
import serverLogger from '../../utils/ServerLogger';
import { format } from 'date-fns';


const NewsDesk = ({ ignoredSources, timezone, selectedSound }) => {

  const { user, isLoading } = useUserData();
  const [news, setNews] = useState([]);
  const [newsLoaded, setNewsLoaded] = useState(false);
  const [filteredNews, setFilteredNews] = useState([]);


  const [sources, setSources] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [showAutoScrollButton, setShowAutoScrollButton] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [userInteracted, setUserInteracted] = useState(false);
  const [error, setError] = useState(null);
  //const [highlightedNewsId, setHighlightedNewsId] = useState(null);


  const newsEndRef = useRef(null);
  const alertSoundRef = useRef(null);
  const wsInitialized = useRef(false);

  const { darkMode } = useTheme();

  const hasPermission = useCallback(
    (permission) => user?.permissions?.includes(permission),
    [user]
  );



  const fetchInitialNews = useCallback(async () => {
    try {
      serverLogger.log('info', 'Fetching news...');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/news`);
      const fetchedNews = response.data;

      setNews(fetchedNews);

      const uniqueSources = [...new Set(fetchedNews.map((item) => item.source))];
      setSources(uniqueSources);

      if (autoScroll && newsEndRef.current) {
        setTimeout(() => {
          newsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
      serverLogger.log('info', 'News fetched successfully.');
    } catch (error) {
      console.error('Error fetching news:', error);
      setError('Error fetching news');
    }
  }, [autoScroll]);

  const handleScroll = useCallback(() => {
    const isAtBottom =
      document.documentElement.scrollHeight - window.innerHeight - window.scrollY < 100;
    setAutoScroll(isAtBottom);
    setShowAutoScrollButton(!isAtBottom);
  }, []);

  const handleEnableAutoScroll = () => {
    setAutoScroll(true);
    setShowAutoScrollButton(false);
    newsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const openDeleteDialog = (id) => {
    setSelectedNewsId(id);
    setConfirmDeleteOpen(true);
  };

  const closeDeleteDialog = () => {
    setConfirmDeleteOpen(false);
    setSelectedNewsId(null);
  };

  const handleDeleteNews = async () => {
    if (selectedNewsId) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/news/${selectedNewsId}`);
        setNews((prevNews) => prevNews.filter((item) => item.id !== selectedNewsId));
        closeDeleteDialog();
      } catch (error) {
        console.error('Error deleting news:', error);
      }
    }
  };
  
  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
  
    };
    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('scroll', handleUserInteraction);
  
    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('scroll', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const loadSound = () => {
      if (selectedSound !== 'none') {
        const soundUrl = `/sounds/${selectedSound}.mp3`;
        console.log(`Loading sound: ${soundUrl}`);
        alertSoundRef.current = new Audio(soundUrl);
        alertSoundRef.current.addEventListener('error', (e) => {
          console.error('Failed to load audio:', e);
        });
      } else {
        alertSoundRef.current = null;
      }
    };
  
    loadSound(); // Last inn lyden hver gang selectedSound endres
  }, [selectedSound]);

  const playAlertSound = () => {
    if (userInteracted && alertSoundRef.current) {
      alertSoundRef.current.play().catch((err) => {
        console.error("Audio playback failed:", err);
      });
    }
  };
  
  useEffect(() => {
    // Filtrer bort nyheter basert på `ignoredSources`
    setFilteredNews(
        news.filter((item) => !ignoredSources.includes(item.source))
    );
}, [news, ignoredSources]);

useEffect(() => {
  if (!hasPermission('flocktrader')) return;

  if (!newsLoaded) {
    fetchInitialNews();
    setNewsLoaded(true);
  }

  if (!wsInitialized.current) {
    initializeWebSocket();
    wsInitialized.current = true;
  }

  const wsListener = (newNews) => {
    const validatedNews = {
      ...newNews,
      date_time: newNews.formattedDateTime
      ? new Date(newNews.formattedDateTime).toISOString()
      : newNews.date_time
      ? new Date(newNews.date_time).toISOString()
      : null, // Håndter tilfelle hvor ingen av feltene finnes
    };

    setNews((prevNews) => {
      const updatedNews = [...prevNews, validatedNews];
      if (autoScroll && newsEndRef.current) {
        setTimeout(() => {
          newsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
      return updatedNews;
    });
    console.log('spiller av lyd');
    playAlertSound();
  };

  addWebSocketListener(wsListener);
  window.addEventListener('scroll', handleScroll);

  return () => {
    removeWebSocketListener(wsListener);
    window.removeEventListener('scroll', handleScroll);
  };
}, [fetchInitialNews, hasPermission, autoScroll, handleScroll, newsLoaded, playAlertSound]);





  const formattedDate = (dateTime) => {
    if (!dateTime) return 'Unknown Date';

    const timezone = localStorage.getItem('timezone') || 'us';
    const parsedDate = new Date(dateTime);

    if (isNaN(parsedDate)) {
        console.error(`Invalid date encountered: ${dateTime}`);
        return 'Invalid Date';
    }

    try {
        if (timezone === 'us') {
            // US Time: Vis som er (12-timers klokke med AM/PM)
            return format(parsedDate, 'MM/dd/yyyy hh:mm:ss a');
        } else {
            // Lokal tid: Legg til 6 timer manuelt og vis som 24-timers klokke
            const localAdjustedDate = new Date(parsedDate.getTime() + 6 * 60 * 60 * 1000);
            return format(localAdjustedDate, 'MM/dd/yyyy HH:mm:ss');
        }
    } catch (error) {
        console.error(`Error formatting date: ${dateTime}`, error);
        return 'Invalid Date';
    }
};

  const formatContentWithLinks = (content) => {
    if (!content) return 'No content available';
  
    const linkRegex = /\[Link\]\((https?:\/\/[^\s)]+)\)/g;
    const parts = content.split(linkRegex);
  
    return parts.map((part, index) => {
      if (linkRegex.test(`[Link](${part})`)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#007FFF', textDecoration: 'underline' }}
          >
            [Link]
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  if (isLoading) return <CircularProgress />;
  if (!user) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error">You need to log in to access the news desk.</Typography>
      </Container>
    );
  }

  if (!hasPermission('flocktrader')) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error">Access Denied: You do not have permission to view the news desk.</Typography>
      </Container>
    );
  }

  if (error) return <Typography color="error">{error}</Typography>;

  return (

      <Container maxWidth="md" sx={{ mt: 2 }}>
        {filteredNews.map((item, index) => (
          <Card
            key={item.id || index}
            sx={{
              mb: 2,
              boxShadow: 3,
              bgcolor: darkMode ? 'background.paper' : '#ffffff',
              color: darkMode ? 'text.primary' : 'text.primary',
              transition: 'background-color 0.5s ease',
              //backgroundColor: highlightedNews === item.id ? '#FFEB3B' : darkMode ? 'background.paper' : '#ffffff',
            }}
          >
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                {item.source}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  whiteSpace: 'pre-line', // Bevarer linjeskift fra databasen
                  color: item.source === 'FT-Fedspeak:' ? '#FF9800' : 'text.primary',
                }}
              >
                {formatContentWithLinks(item.content) || 'No content available'}
                
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: 'block', mt: 1 }}
              >
                {formattedDate(item.date_time)}
               
              </Typography>
            </CardContent>
            {hasPermission('flockowner') && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <IconButton
                  onClick={() => openDeleteDialog(item.id)}
                  color="error"
                  size="small"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            )}
          </Card>
        ))}
        <div ref={newsEndRef} />
      
      {showAutoScrollButton && (
        <Button
          variant="contained"
          sx={{ bgcolor: 'primary.main' }}
          onClick={handleEnableAutoScroll}
          style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
        >
          Enable Auto-Scroll
        </Button>
      )}
      <Dialog open={confirmDeleteOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this news item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteNews} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      </Container>
  );
};

export default NewsDesk;
