import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Modal,
  IconButton,
  CircularProgress,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewsIcon from '@mui/icons-material/Article';
import { useUserData } from '../auth/UserData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  const { user, isLoading } = useUserData();
  const navigate = useNavigate(); // For navigasjon til admin dashboard
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [source, setSource] = useState('');
  const [content, setContent] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const ADMIN_ROLE_ID = process.env.REACT_APP_FLOCKADMIN_ROLE_ID;
  const MOD_ROLE_ID = process.env.REACT_APP_FLOCKMOD_ROLE_ID;
  const isAdminOrMod = user?.roles?.includes(ADMIN_ROLE_ID) || user?.roles?.includes(MOD_ROLE_ID);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSource('');
    setContent('');
  };

  const handleAddNews = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/news/add`,
        { source, content },
        { withCredentials: true }
      );
      setSuccessMessage('News added successfully!');
      setErrorMessage('');
      handleCloseModal();
    } catch (error) {
      console.error('Error adding news:', error);
      setErrorMessage('Failed to add news.');
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!user || !isAdminOrMod) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error" textAlign="center" mt={4}>
          You do not have permission to access this page.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Welcome to the Admin Panel
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {/* News Management */}
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <NewsIcon color="primary" fontSize="large" />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Manage News
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 2 }}>
                  Add and manage news updates for the platform.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpenModal} fullWidth>
                  Add News
                </Button>
                {successMessage && (
                  <Typography color="success.main" mt={2} textAlign="center">
                    {successMessage}
                  </Typography>
                )}
                {errorMessage && (
                  <Typography color="error.main" mt={2} textAlign="center">
                    {errorMessage}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Admin Dashboard */}
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <DashboardIcon color="primary" fontSize="large" />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Admin Dashboard
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 2 }}>
                  View platform statistics and manage users.
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate('/dashboard/admin/dashboard')}
                  fullWidth
                >
                  Go to Dashboard
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Modal for Adding News */}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Add News</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              label="Source"
              fullWidth
              value={source}
              onChange={(e) => setSource(e.target.value)}
              margin="normal"
            />
            <TextField
              label="Content"
              fullWidth
              multiline
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNews}
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
};

export default AdminPanel;
