import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import axios from 'axios';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Hent brukere fra backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users`);
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Filtrering basert på søk
  useEffect(() => {
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  // Åpne detaljvisning
  const openDialog = (user) => {
    setSelectedUser({ ...user }); // Kopierer brukeren for redigering
    setIsDialogOpen(true);
  };

  // Lukk detaljvisning
  const closeDialog = () => {
    setSelectedUser(null);
    setIsDialogOpen(false);
    setIsEditing(false);
  };

 // Validering før oppdatering
const validateUserData = () => {
    if (!selectedUser.email || !/^\S+@\S+\.\S+$/.test(selectedUser.email)) {
      alert('Please enter a valid email address.');
      return false;
    }
    if (!selectedUser.username.trim()) {
      alert('Username cannot be empty.');
      return false;
    }
    return true;
  };
  
  // Lagre oppdateringer med validering
  const saveUpdates = async () => {
    if (!validateUserData()) return;
  
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users/${selectedUser.internalId}`, {
        email: selectedUser.email,
        username: selectedUser.username,
        roles: selectedUser.roles,
      });
      setUsers((prev) =>
        prev.map((user) =>
          user.internalId === selectedUser.internalId ? { ...selectedUser } : user
        )
      );
      closeDialog();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const deleteUser = async () => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
  
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users/${selectedUser.internalId}`);
      setUsers((prev) =>
        prev.filter((user) => user.internalId !== selectedUser.internalId)
      );
      closeDialog();
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. Please try again.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manage Users
      </Typography>

      {/* Søke-felt */}
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {/* Tabell med brukere */}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.internalId} onClick={() => openDialog(user)}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.roles.join(', ')}</TableCell>
                <TableCell>{new Date(user.lastLogin).toLocaleString()}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation(); // Forhindrer at raden også åpner dialogen
                      openDialog(user);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Dialog for detaljvisning */}
      {selectedUser && (
        <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="sm">
          <DialogTitle>{isEditing ? 'Edit User' : 'User Details'}</DialogTitle>
          <DialogContent>
            <Typography variant="h6">General Information</Typography>
            <TextField
              fullWidth
              margin="normal"
              label="User ID"
              value={selectedUser.userId}
              InputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Username"
              value={selectedUser.username}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, username: e.target.value })
              }
              InputProps={{ readOnly: !isEditing }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              value={selectedUser.email}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, email: e.target.value })
              }
              InputProps={{ readOnly: !isEditing }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Roles (comma-separated)"
              value={selectedUser.roles.join(', ')}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  roles: e.target.value.split(',').map((r) => r.trim()),
                })
              }
              InputProps={{ readOnly: !isEditing }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Last Login"
              value={new Date(selectedUser.lastLogin).toLocaleString()}
              InputProps={{ readOnly: true }}
            />
          </DialogContent>
          <DialogActions>
            {isEditing ? (
              <>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={saveUpdates}>
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
            )}
                <Button onClick={deleteUser} color="error">
    Delete
  </Button>
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Users;
