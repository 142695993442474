import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Avatar,
    Paper,
    IconButton,
    CircularProgress,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MouseIcon from '@mui/icons-material/Mouse';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const NewsletterDashboard = () => {
    const [subscriberCount, setSubscriberCount] = useState(0);
    const [recentNewsletters, setRecentNewsletters] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const [subscriberRes, newslettersRes] = await Promise.all([
                    axios.get('/api/analytics/subscriber-count'),
                    axios.get('/api/analytics/recent-newsletters'),
                ]);
                setSubscriberCount(subscriberRes.data.count || 0);
                setRecentNewsletters(newslettersRes.data || []);
            } catch (error) {
                console.error('Error fetching dashboard data:', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    const calculatePercentage = (value, total) =>
        total > 0 ? ((value / total) * 100).toFixed(2) : '0.00';

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box p={4}>
            {/* Header Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography variant="h4" fontWeight="bold">
                    Dine siste nyhetsbrev
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('create')}
                >
                    Lag en ny kampanje
                </Button>
            </Box>

            {/* Subscriber Count */}
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    Oversikt
                </Typography>
                <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                    <Typography variant="h5" fontWeight="bold">
                        {subscriberCount}
                    </Typography>
                    <Typography>Antall abonnenter</Typography>
                </Paper>
            </Box>

            {/* Recent Newsletters */}
            <Box>
                <Typography variant="h6" gutterBottom>
                    De 5 siste nyhetsbrevene
                </Typography>
                {recentNewsletters.length > 0 ? (
                    recentNewsletters.map((newsletter) => (
                        <Paper
                            key={newsletter.id}
                            elevation={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                marginBottom: 2,
                            }}
                        >
                            {/* Newsletter Details */}
                            <Box display="flex" alignItems="center" flex={1}>
                                <Avatar sx={{ marginRight: 2 }}>N</Avatar>
                                <Box>
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        sx={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => navigate(`${newsletter.id}`)}
                                    >
                                        {newsletter.subject || 'Ukjent emne'}
                                    </Typography>
                                    <Typography variant="body2">
                                        Sendt{' '}
                                        {newsletter.sent_at
                                            ? new Date(newsletter.sent_at).toLocaleString()
                                            : 'Ingen data'}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color:
                                                newsletter.sent === 'Sending'
                                                    ? 'orange'
                                                    : 'green',
                                            animation:
                                                newsletter.sent === 'Sending'
                                                    ? 'blink 1s infinite'
                                                    : 'none',
                                            '@keyframes blink': {
                                                '0%': { opacity: 1 },
                                                '50%': { opacity: 0.5 },
                                                '100%': { opacity: 1 },
                                            },
                                        }}
                                    >
                                        {newsletter.sent === 'Sending' ? 'Sending...' : 'Sendt'}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Metrics */}
                            <Box textAlign="center" display="flex" alignItems="center">
                                <IconButton>
                                    <PeopleIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ marginRight: 3 }}>
                                    {newsletter.total_recipients || 0}
                                </Typography>
                            </Box>
                            <Box textAlign="center" display="flex" alignItems="center">
                                <IconButton>
                                    <VisibilityIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ marginRight: 3 }}>
                                    {calculatePercentage(
                                        newsletter.opens,
                                        newsletter.total_recipients
                                    )}
                                    %
                                </Typography>
                            </Box>
                            <Box textAlign="center" display="flex" alignItems="center">
                                <IconButton>
                                    <MouseIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ marginRight: 3 }}>
                                    {calculatePercentage(
                                        newsletter.clicks,
                                        newsletter.total_recipients
                                    )}
                                    %
                                </Typography>
                            </Box>

                            {/* Action */}
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => navigate(`${newsletter.id}/statistics`)}
                                >
                                    Statistikk
                                </Button>
                            </Box>
                        </Paper>
                    ))
                ) : (
                    <Typography color="textSecondary">
                        Ingen nylige nyhetsbrev funnet.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default NewsletterDashboard;
