import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Divider,
    Button,
    List,
    ListItem,
    ListItemText,
    Modal,
    Pagination,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MouseIcon from '@mui/icons-material/Mouse';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const NewsletterStatistics = ({ newsletter }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState('Mottakere');
    const [modalContent, setModalContent] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [linkClicks, setLinkClicks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [uniqueOpens, setUniqueOpens] = useState(0); // Standard til 0

    useEffect(() => {
        const fetchLinkClicks = async () => {
            try {
                const response = await axios.get(
                    `/api/analytics/newsletter-click-logs/${newsletter.id}`
                );

                const groupedClicks = response.data.reduce((acc, curr) => {
                    const existing = acc.find((item) => item.url === curr.url);
                    if (existing) {
                        existing.click_count += curr.click_count;
                    } else {
                        acc.push({ url: curr.url, click_count: curr.click_count });
                    }
                    return acc;
                }, []);

                setLinkClicks(groupedClicks);
            } catch (error) {
                console.error('Error fetching link clicks:', error.message);
            }
        };

        fetchLinkClicks();
    }, [newsletter.id]);

    useEffect(() => {
        const fetchOpenLogs = async () => {
            try {
                const response = await axios.get(
                    `/api/analytics/newsletter-open-logs/${newsletter.id}`
                );
    
                // Logg hele responsen for debugging
                //console.log('Open Logs Response:', response.data);
    
                // Hent unike åpninger fra API-responsen
                const uniqueOpenCount = response.data.unique_open_count || 0; // Antall unike åpninger
                setUniqueOpens(uniqueOpenCount); // Oppdater tilstanden
            } catch (error) {
                console.error('Error fetching open logs:', error.message);
            }
        };
    
        fetchOpenLogs();
    }, [newsletter.id]);

    useEffect(() => {
        const fetchClicks = async () => {
            try {
                const response = await axios.get(`/api/analytics/newsletter-click-logs/${newsletter.id}`);
                
                // Gruppér klikk etter e-post og summer antall klikk
                const groupedClicks = response.data.reduce((acc, curr) => {
                    if (!acc[curr.email]) {
                        acc[curr.email] = {
                            email: curr.email,
                            name: curr.name,
                            click_count: curr.click_count || 0,
                            last_click: curr.last_click || null,
                        };
                    } else {
                        acc[curr.email].click_count += curr.click_count || 0;
                        acc[curr.email].last_click = curr.last_click > acc[curr.email].last_click
                            ? curr.last_click
                            : acc[curr.email].last_click;
                    }
                    return acc;
                }, {});
    
                // Konverter objekt til array
                const groupedData = Object.values(groupedClicks).sort((a, b) => {
                    return new Date(b.last_click) - new Date(a.last_click);
                });
                
                setModalContent(groupedData);
            } catch (error) {
                console.error('Error fetching click logs:', error.message);
            }
        };
    
        if (currentSection === 'Klikk') {
            fetchClicks();
        }
    }, [currentSection, newsletter.id]);

    const calculatePercentage = (value, total) =>
        total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';

    const pieData = {
        labels: ['Åpninger', 'Klikk', 'Avmeldinger', 'Returer'],
        datasets: [
            {
                data: [
                    newsletter.opens,
                    newsletter.clicks,
                    newsletter.unsubscribes,
                    newsletter.bounces,
                ],
                backgroundColor: ['#4caf50', '#2196f3', '#ff9800', '#f44336'],
                hoverBackgroundColor: ['#66bb6a', '#42a5f5', '#ffb74d', '#e57373'],
                borderWidth: 1,
            },
        ],
    };

    const handleOpenModal = async (section) => {
        try {
            setCurrentSection(section); // Oppdater seksjon
            let response;
    
            if (section === 'Mottakere') {
                response = await axios.get(`/api/analytics/newsletter-recipients/${newsletter.id}`);
                setModalContent(response.data); // Sett data direkte
            } else if (section === 'Åpninger') {
                response = await axios.get(`/api/analytics/newsletter-open-logs/${newsletter.id}`);
                setModalContent(response.data.unique_opens || []); // Sett kun unique_opens
            } else if (section === 'Klikk') {
                response = await axios.get(`/api/analytics/newsletter-click-logs/${newsletter.id}`);
                setModalContent(response.data); // Sett data direkte
            }
    
            setModalOpen(true); // Åpne modal
        } catch (error) {
            console.error(`Error fetching data for ${section}:`, error.message);
            setModalContent([]); // Sett en tom liste ved feil
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const paginatedContent = Array.isArray(modalContent)
    ? modalContent.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
      )
    : [];

    const renderDetails = (item) => {
        if (currentSection === 'Mottakere') {
            return (
                <>
                    <Typography variant="body2">
                        Aktivitet: 
                        {item.activities?.includes('Åpnet') && <VisibilityIcon sx={{ mr: 1 }} />}
                        {item.activities?.includes('Klikket') && <MouseIcon />}
                        {!item.activities?.includes('Åpnet') &&
                            !item.activities?.includes('Klikket') && 'Ingen aktivitet'}
                    </Typography>
                    {item.last_activity && (
                        <Typography variant="body2" color="textSecondary">
                            Sist aktivitet: {new Date(item.last_activity).toLocaleString()}
                        </Typography>
                    )}
                </>
            );
        } else if (currentSection === 'Åpninger') {
            return (
                <>
                    <Typography variant="body2">
                        Antall åpninger: {item.open_count || 0}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Siste åpning: {item.last_open ? new Date(item.last_open).toLocaleString() : 'Ingen data'}
                    </Typography>
                </>
            );
        } else if (currentSection === 'Klikk') {
            return (
                <>
                    <Typography variant="body2">
                        Antall klikk: {item.click_count || 0}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Siste klikk: {item.last_click ? new Date(item.last_click).toLocaleString() : 'Ingen data'}
                    </Typography>
                </>
            );
        } else {
            return <Typography>Ingen data tilgjengelig.</Typography>;
        }
    };

    return (
        <Box p={4}>

            <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Paper
                    elevation={3}
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Visuell oppsummering
                    </Typography>
                    <Box sx={{ width: '300px', height: '300px' }}>
                        <Pie
                            data={pieData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>

    <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper
                    elevation={3}
                    sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => handleOpenModal('Mottakere')}
                >
                    <PeopleIcon fontSize="large" color="primary" />
                    <Typography variant="h6" fontWeight="bold">
                        {newsletter.total_recipients}
                    </Typography>
                    <Typography>Mottakere</Typography>
                </Paper>
            </Grid>

            <Grid item xs={6}>
            <Paper
                elevation={3}
                sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenModal('Åpninger')}
            >
                <VisibilityIcon fontSize="large" color="success" />
                <Typography variant="h6" fontWeight="bold">
                    {calculatePercentage(uniqueOpens, newsletter.total_recipients || 0)}%
                </Typography>
                <Typography>Åpninger ({uniqueOpens})</Typography>
            </Paper>
        </Grid>

            <Grid item xs={6}>
                <Paper
                    elevation={3}
                    sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => handleOpenModal('Klikk')}
                >
                    <MouseIcon fontSize="large" color="info" />
                    <Typography variant="h6" fontWeight="bold">
                        {calculatePercentage(newsletter.clicks, newsletter.total_recipients)}%
                    </Typography>
                    <Typography>Klikk ({newsletter.clicks})</Typography>
                </Paper>
            </Grid>
        </Grid>
    </Grid>
</Grid>

            <Divider sx={{ my: 4 }} />
            <Box>
            <Typography variant="h6" gutterBottom>
                Klikk på lenker
            </Typography>
            <Paper elevation={3} sx={{ p: 3 }}>
                {linkClicks.length > 0 ? (
                    <List>
                        {linkClicks.map((link, index) => (
                            <ListItem key={index} divider>
                                <ListItemText
                                    primary={link.url || 'Ukjent URL'} // Håndter tilfelle der URL mangler
                                    secondary={`Totalt klikk: ${link.click_count || 0}`} // Sett fallback til 0
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>Ingen klikk registrert på lenker.</Typography>
                )}
            </Paper>
        </Box>

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: 3,
                        width: '80%',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {modalTitle}
                    </Typography>
                    <List>
                    {paginatedContent.length > 0 ? (
                        paginatedContent.map((item, index) => (
                            <ListItem key={index} divider>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" fontWeight="bold">
                                            {item.name || 'Ukjent'} ({item.email || 'Ingen e-post'})
                                        </Typography>
                                    }
                                    secondary={renderDetails(item)}
                                />
                            </ListItem>
                        ))
                    ) : (
                        <Typography>Ingen data tilgjengelig.</Typography>
                    )}
                </List>
                    <Pagination
                        count={Math.ceil(modalContent.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ mt: 2 }}
                    />
                </Paper>
            </Modal>
        </Box>
    );
};

export default NewsletterStatistics;
