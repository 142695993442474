import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const QuizDetails = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [quizDetails, setQuizDetails] = useState(null);

  const [score, setScore] = useState(0);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        const response = await axios.get(`/api/academy/courses/${courseId}/quizzes`);
        console.log('Quiz response:', response.data);

        if (response.data.length > 0) {
          const quiz = response.data[0]; // Ta den første quizen i listen
          setQuizDetails(quiz);

          const questionsResponse = await axios.get(`/api/academy/quizzes/${quiz.id}/questions`);
          setQuestions(questionsResponse.data);
        } else {
          console.error('No quiz found for this course.');
          setQuizDetails(null);
        }
      } catch (error) {
        console.error('Error fetching quiz details:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizDetails();
  }, [courseId]);

  const handleAnswerSubmit = () => {
    if (questions[currentQuestionIndex]?.correctOption === selectedOption) {
      setScore((prevScore) => prevScore + 1);
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption('');
    } else {
      setCompleted(true);
    }
  };

  if (loading) return <CircularProgress />;

  if (quizDetails === null) {
    return (
      <Box textAlign="center" p={4}>
        <Typography variant="h5" color="textSecondary">
          Det finnes ingen quiz for dette kurset.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/academy/course/${courseId}`)}
          sx={{ mt: 4 }}
        >
          Tilbake til kurset
        </Button>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Typography variant="h4" mb={4}>
        {quizDetails?.title || 'Quiz'}
      </Typography>

      <Divider sx={{ mb: 4 }} />

      {completed ? (
        <Box textAlign="center">
          <Typography variant="h5" fontWeight="bold">
            Gratulerer!
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={4}>
            Du fikk {score} av {questions.length} riktige.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/academy/course/${courseId}`)}
          >
            Tilbake til kurset
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            {questions[currentQuestionIndex]?.questionText}
          </Typography>

          {questions[currentQuestionIndex]?.content && (
            <Typography
              component="div"
              dangerouslySetInnerHTML={{
                __html: questions[currentQuestionIndex]?.content,
              }}
              sx={{ mb: 4 }}
            />
          )}

          <FormControl component="fieldset">
            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <FormControlLabel
                value="A"
                control={<Radio />}
                label={questions[currentQuestionIndex]?.optionA}
              />
              <FormControlLabel
                value="B"
                control={<Radio />}
                label={questions[currentQuestionIndex]?.optionB}
              />
              <FormControlLabel
                value="C"
                control={<Radio />}
                label={questions[currentQuestionIndex]?.optionC}
              />
              <FormControlLabel
                value="D"
                control={<Radio />}
                label={questions[currentQuestionIndex]?.optionD}
              />
            </RadioGroup>
          </FormControl>

          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAnswerSubmit}
              disabled={!selectedOption}
            >
              {currentQuestionIndex < questions.length - 1 ? 'Neste' : 'Fullfør Quiz'}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuizDetails;
